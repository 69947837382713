<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'une catégorie d'ouvrage</span>
            <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition de la catégorie d'ouvrage</span>
            <span *ngIf="mode === 'DUPLICATE' && !isReadOnly">Duplication de la catégorie d'ouvrage</span>
            <span *ngIf="isReadOnly">Consultation de la catégorie d'ouvrage</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button
                *ngIf="!isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="formCategorieOuvrage.invalid"
                (click)="validerCategorieOuvrage()"
            >
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
            <button
                *ngIf="isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="categorieOuvrage.etatCategorieOuvrage === 'ACTIF'"
                (click)="editer()"
            >
                <mat-icon>edit</mat-icon>
                <span>{{ 'Éditer' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>

<div *ngIf="formCategorieOuvrage">
    <div class="h-100 row no-gutters p-4 scrollable-content" [formGroup]="formCategorieOuvrage">
        <!-- Colonne gauche-->
        <div class="col-12">
            <mat-button-toggle-group appearance="legacy" *ngIf="!isReadOnly" formControlName="etatCategorieOuvrage" class="mb-3">
                <mat-button-toggle class="button-toggle-double INACTIF left-toggle" value="INACTIF">Inactif </mat-button-toggle>
                <mat-button-toggle class="button-toggle-double ACTIF right-toggle" value="ACTIF">Actif </mat-button-toggle>
            </mat-button-toggle-group>

            <div *ngIf="isReadOnly" class="statut-workflow consultation mb-3" [ngClass]="categorieOuvrage.etatCategorieOuvrage">
                {{ categorieOuvrage.etatCategorieOuvrage | etatWorkflow }}
            </div>
        </div>

        <div class="col-xl-6 pr-2">
            <!-- Etat de l'équipement -->

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Informations générales</div>
                    <!-- Nom de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom de la catégorie d'ouvrage</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput [readonly]="isReadOnly" type="text" formControlName="nom" />
                                <mat-error *ngIf="formCategorieOuvrage.get('nom').hasError('erreurNomEquipement')"
                                    >Un autre équipement possède déjà le même nom.
                                </mat-error>
                                <app-mat-error [control]="formCategorieOuvrage.get('nom')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="formCategorieOuvrage.get('lienCodeParent').value" class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Nom des ouvrages générés</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput [readonly]="isReadOnly" type="text" formControlName="nomOuvrage" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Type d'incrémentation lors de la génération</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-select formControlName="typeIncrementation" [disabled]="isReadOnly">
                                    <mat-option *ngFor="let type of listTypeIncrementation" [value]="type.value">
                                        {{ type.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Groupe d'incrémentation</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput [readonly]="isReadOnly" type="text" formControlName="incrementationGroup" />
                                <app-mat-error [control]="formCategorieOuvrage.get('incrementationGroup')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- code de la catégorie d'ouvrage -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code de la catégorie d'ouvrage</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput [readonly]="isReadOnly" type="text" formControlName="code" />
                                <app-mat-error [control]="formCategorieOuvrage.get('code')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <!--Colonne droite-->
        <div class="col-xl-6 pl-2">
            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 p-2 m-0 primary">Réglages</div>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Catégorie d'ouvrage parent</div>
                        <div class="col-xl-8">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-select formControlName="lienCodeParent" [disabled]="isReadOnly">
                                    <mat-option [value]="" class="font-weight-lighter font-italic">Aucune</mat-option>
                                    <mat-option *ngFor="let cOuvrage of mapCategoriesOuvrages.values()" [value]="cOuvrage.code">
                                        {{ cOuvrage.nom }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 d-flex p-2 m-0 primary justify-content-between">
                        <div class="card-title">
                            <mat-slide-toggle [disabled]="isReadOnly" formControlName="activatedRevetements"></mat-slide-toggle>
                            <span>Revêtements</span>
                        </div>
                        <button
                            mat-raised-button
                            color="primary"
                            [disabled]="
                                !formCategorieOuvrage.get('lienCodeParent').value ||
                                !formCategorieOuvrage.get('activatedRevetements').value ||
                                isReadOnly
                            "
                            (click)="addRevetement()"
                        >
                            <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">add</mat-icon
                            >{{ 'Ajouter un revêtement' | uppercase }}
                        </button>
                    </div>
                    <div *ngIf="!formCategorieOuvrage.get('lienCodeParent').value" class="col-xl-12 d-flex m-0">
                        Les revêtements ne sont disponibles que pour une catégorie enfant (qui possède une catégorie d'ouvrage parent)
                    </div>
                    <ng-container
                        *ngIf="formCategorieOuvrage.get('lienCodeParent').value && formCategorieOuvrage.get('activatedRevetements').value"
                        formArrayName="revetements"
                    >
                        <ng-container *ngFor="let revetement of formRevetements.controls; let i = index">
                            <div [formGroupName]="i.toString()" class="d-flex p-2 align-items-center justify-content-between">
                                <div class="col-xl-5 mat-subheading-2 d-flex row no-gutters align-items-center m-0 p-0 primary">
                                    <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom</div>
                                    <div class="col-xl-8">
                                        <mat-form-field class="w-100">
                                            <input
                                                matInput
                                                [readonly]="isReadOnly || revetement.value.code === BRUT"
                                                type="text"
                                                formControlName="nom"
                                            />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xl-5 mat-subheading-2 d-flex row no-gutters align-items-center m-0 p-0 primary">
                                    <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code</div>
                                    <div class="col-xl-8">
                                        <mat-form-field class="w-100">
                                            <input
                                                matInput
                                                [readonly]="isReadOnly || revetement.value.code === BRUT"
                                                type="text"
                                                formControlName="code"
                                            />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xl-1">
                                    <button
                                        *ngIf="!isReadOnly && revetement.value.code != BRUT"
                                        mat-icon-button
                                        color="warn"
                                        (click)="deleteRevetement(i)"
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div
                        *ngIf="
                            formCategorieOuvrage.get('lienCodeParent').value &&
                            formCategorieOuvrage.get('activatedRevetements').value &&
                            mapRevetements &&
                            mapRevetements.size > 0
                        "
                        class="w-100 d-flex justify-content-center"
                    >
                        <button mat-raised-button color="primary" [matMenuTriggerFor]="menuRevetements" [disabled]="isReadOnly">Copier depuis</button>
                        <mat-menu #menuRevetements="matMenu">
                            <button
                                *ngFor="let revetement of mapRevetements | keyvalue"
                                mat-menu-item
                                (click)="handleCopierRevetement(revetement.key)"
                            >
                                {{ revetement.key }}
                            </button>
                        </mat-menu>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 d-flex p-2 m-0 primary justify-content-between">
                        <div class="card-title">
                            <mat-slide-toggle [disabled]="isReadOnly" formControlName="activatedSubstrats"></mat-slide-toggle>
                            <span>Substrat</span>
                        </div>
                        <button
                            mat-raised-button
                            color="primary"
                            [disabled]="
                                !formCategorieOuvrage.get('lienCodeParent').value ||
                                !formCategorieOuvrage.get('activatedSubstrats').value ||
                                isReadOnly
                            "
                            (click)="addSubstrat()"
                        >
                            <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">add</mat-icon
                            >{{ 'Ajouter un substrat' | uppercase }}
                        </button>
                    </div>
                    <div *ngIf="!formCategorieOuvrage.get('lienCodeParent').value" class="col-xl-12 d-flex m-0">
                        Les substrats ne sont disponibles que pour une catégorie enfant (qui possède une catégorie d'ouvrage parent)
                    </div>
                    <ng-container
                        *ngIf="formCategorieOuvrage.get('lienCodeParent').value && formCategorieOuvrage.get('activatedSubstrats').value"
                        formArrayName="substrats"
                    >
                        <ng-container *ngFor="let substrat of formSubstrats.controls; let i = index">
                            <div [formGroupName]="i.toString()" class="d-flex p-2 align-items-center justify-content-between">
                                <div class="col-xl-5 mat-subheading-2 d-flex row no-gutters align-items-center m-0 p-0 primary">
                                    <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom</div>
                                    <div class="col-xl-8">
                                        <mat-form-field class="w-100">
                                            <input matInput [readonly]="isReadOnly" type="text" formControlName="nom" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xl-5 mat-subheading-2 d-flex row no-gutters align-items-center m-0 p-0 primary">
                                    <div class="col-xl-4 mat-subheading-2 m-0 primary required">Code</div>
                                    <div class="col-xl-8">
                                        <mat-form-field class="w-100">
                                            <input matInput [readonly]="isReadOnly" type="text" formControlName="code" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xl-1">
                                    <button *ngIf="!isReadOnly" mat-icon-button color="warn" (click)="deleteSubstrat(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div
                        *ngIf="
                            formCategorieOuvrage.get('lienCodeParent').value &&
                            formCategorieOuvrage.get('activatedSubstrats').value &&
                            mapSubstrats &&
                            mapSubstrats.size > 0
                        "
                        class="w-100 d-flex justify-content-center"
                    >
                        <button mat-raised-button color="primary" [disabled]="isReadOnly" [matMenuTriggerFor]="menuSubstrats">Copier depuis</button>
                        <mat-menu #menuSubstrats="matMenu">
                            <button *ngFor="let substrat of mapSubstrats | keyvalue" mat-menu-item (click)="handleCopierSubstrat(substrat.key)">
                                {{ substrat.key }}
                            </button>
                        </mat-menu>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 d-flex p-2 m-0 primary justify-content-between">
                        <div class="card-title">
                            <mat-slide-toggle [disabled]="isReadOnly" formControlName="activatedCouleurs"></mat-slide-toggle>
                            <span>Couleurs</span>
                        </div>
                        <button
                            mat-raised-button
                            color="primary"
                            [disabled]="
                                !formCategorieOuvrage.get('lienCodeParent').value ||
                                !formCategorieOuvrage.get('activatedCouleurs').value ||
                                isReadOnly
                            "
                            (click)="addCouleur()"
                        >
                            <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">add</mat-icon
                            >{{ 'Ajouter une couleur' | uppercase }}
                        </button>
                    </div>
                    <div *ngIf="!formCategorieOuvrage.get('lienCodeParent').value" class="col-xl-12 d-flex m-0">
                        Les couleurs ne sont disponibles que pour une catégorie enfant (qui possède une catégorie d'ouvrage parent)
                    </div>
                    <ng-container
                        *ngIf="formCategorieOuvrage.get('lienCodeParent').value && formCategorieOuvrage.get('activatedCouleurs').value"
                        formArrayName="couleurs"
                    >
                        <ng-container *ngFor="let couleur of formCouleurs.controls; let i = index">
                            <div [formGroupName]="i.toString()" class="d-flex p-2 align-items-center justify-content-between">
                                <div class="col-xl-5 mat-subheading-2 d-flex row no-gutters align-items-center m-0 p-0 primary">
                                    <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom</div>
                                    <div class="col-xl-8">
                                        <mat-form-field class="w-100">
                                            <input matInput [readonly]="isReadOnly" type="text" formControlName="nom" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xl-5 mat-subheading-2 d-flex row no-gutters align-items-center m-0 p-0 primary">
                                    <div class="col-xl-4 mat-subheading-2 m-0 primary required">Valeur</div>
                                    <div class="col-xl-8">
                                        <input class="w-100" matInput [readonly]="isReadOnly" type="color" formControlName="valeur" />
                                    </div>
                                </div>
                                <div class="col-xl-1">
                                    <button *ngIf="!isReadOnly" mat-icon-button color="warn" (click)="deleteCouleur(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div
                        *ngIf="
                            formCategorieOuvrage.get('lienCodeParent').value &&
                            formCategorieOuvrage.get('activatedCouleurs').value &&
                            mapCouleurs &&
                            mapCouleurs.size > 0
                        "
                        class="w-100 d-flex justify-content-center"
                    >
                        <button mat-raised-button color="primary" [matMenuTriggerFor]="menuCouleurs" [disabled]="isReadOnly">Copier depuis</button>
                        <mat-menu #menuCouleurs="matMenu">
                            <button *ngFor="let couleur of mapCouleurs | keyvalue" mat-menu-item (click)="handleCopierCouleur(couleur.key)">
                                {{ couleur.key }}
                            </button>
                        </mat-menu>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-12">
            <mat-card class="p-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <!-- Paramètres de l'ouvrage -->
                    <div class="d-flex flex-column p-2 align-items-center">
                        <div class="col-xl-12 d-flex mat-subheading-2 m-0 primary justify-content-between">
                            <span>Paramètres</span>
                            <button
                                mat-raised-button
                                color="primary"
                                (click)="addParametre()"
                                [disabled]="!formCategorieOuvrage.get('lienCodeParent').value || isReadOnly"
                            >
                                <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">add</mat-icon
                                >{{ 'Ajouter un paramètre' | uppercase }}
                            </button>
                        </div>
                        <div *ngIf="!formCategorieOuvrage.get('lienCodeParent').value" class="col-xl-12 d-flex m-0">
                            Les paramètres ne sont disponibles que pour une catégorie enfant (qui possède une catégorie d'ouvrage parent)
                        </div>
                        <div
                            *ngIf="formCategorieOuvrage.get('lienCodeParent').value"
                            class="d-flex flex-column w-100"
                            formArrayName="parametres"
                            cdkDropList
                            cdkDropListOrientation="vertical"
                            [cdkDropListDisabled]="isReadOnly"
                            (cdkDropListDropped)="dropParametre($event)"
                        >
                            <div *ngFor="let param of formParametres.controls; let i = index" class="d-flex w-100" cdkDrag [cdkDragData]="param">
                                <div [formGroupName]="i.toString()" class="d-flex w-100">
                                    <span cdkDragHandle class="c-grabbable d-flex pt-3">
                                        <lib-icon>drag_indicator</lib-icon>
                                    </span>
                                    <mat-form-field class="col-3 px-1" appearance="outline">
                                        <mat-label>Label</mat-label>
                                        <input matInput [readonly]="isReadOnly" formControlName="label" type="text" />
                                    </mat-form-field>
                                    <mat-form-field class="col-3 px-1" appearance="outline">
                                        <mat-label>Code</mat-label>
                                        <input matInput [readonly]="isReadOnly" formControlName="code" type="text" />
                                    </mat-form-field>
                                    <div class="d-flex align-items-center mat-subheading-2 m-0 ml-2 primary required">Type</div>
                                    <div class="col-5 d-flex align-items-center">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-select formControlName="type" [disabled]="isReadOnly">
                                                <mat-option [value]="" class="font-weight-lighter font-italic">Aucune</mat-option>
                                                <mat-option *ngFor="let typeParam of typeParametreCategorieOuvrage" [value]="typeParam.value">
                                                    {{ typeParam.label }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-1 d-flex align-items-center justify-content-end">
                                    <button
                                        *ngIf="!isReadOnly && param.get('type').value === TypeParametreCategorieOuvrage.SIMPLE_CHOICE"
                                        mat-icon-button
                                        (click)="configureValues(param.get('values'))"
                                    >
                                        <mat-icon>settings</mat-icon>
                                    </button>
                                    <button *ngIf="!isReadOnly" mat-icon-button color="warn" (click)="deleteParametre(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { BienService } from './bien.service';
import { DiagnosticHandlerService } from './diagnostic-handler.service';
import { Bien, Niveau, Volume } from '../model/bien.model';
import { Diagnostic, EtatDiagnostic } from '../model/diagnostic.model';
import { InterventionService } from './intervention.service';
import { DiagnosticService } from './diagnostic.service';
import { Intervention } from '../model/intervention.model';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { copyAndIncrementNumerotation, DescriptionBienService, isANumber } from './description-bien.service';
import { TypeVolume } from '../model/type-element-a-controler.model';
import { ReferenceService } from './reference.service';
import { max } from '../utils/list.utils';

@Injectable({
    providedIn: 'root',
})
export class MergeBienService {
    constructor(
        private readonly bienService: BienService,
        private readonly diagnosticHandlerService: DiagnosticHandlerService,
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly descriptionBienService: DescriptionBienService,
        private readonly referenceService: ReferenceService
    ) {}

    transfertVolumeToNiveau(bien: Bien, volume: Volume, niveauCible: Niveau, intervention) {
        return this.updateAllDiagnosticsAndIntervention(intervention, (intervention, diagnostics) => {
            const niveauActuel = this.findNiveauForVolume(bien, volume);

            volume.indexEspace = (max(niveauCible.volumes, 'indexEspace') ?? 0) + 1;
            copyAndIncrementNumerotation(volume, niveauCible.volumes);

            volume.nom = this.descriptionBienService.generateVolumeName(volume, niveauCible);
            niveauCible.volumes.push(volume);
            this.removeVolumeFromNiveau(niveauActuel, volume);
            diagnostics.forEach((diag) => {
                this.diagnosticHandlerService.getTypePrestationService(diag.typePrestation).deplaceVolume(diag, volume, niveauCible, bien);
            });
            niveauCible.volumes.forEach((vol) => (vol.nom = this.descriptionBienService.generateVolumeName(vol, niveauCible)));
            niveauActuel.volumes.forEach((vol) => (vol.nom = this.descriptionBienService.generateVolumeName(vol, niveauActuel)));
            return of([intervention, diagnostics]);
        });
    }

    mergeVolumes(bien: Bien, volumeActuel: Volume, targetVolume: Volume, intervention: Intervention) {
        return this.updateAllDiagnosticsAndIntervention(intervention, (interventionUpdated, diagnostics) => {
            const targetNiveau = this.findNiveauForVolume(bien, targetVolume);
            targetVolume.nomBase = volumeActuel.nomBase;
            targetVolume.usageId = volumeActuel.usageId;
            targetVolume.nom = this.descriptionBienService.generateVolumeName(targetVolume, targetNiveau);
            targetVolume.equipements = targetVolume.equipements.concat(volumeActuel.equipements);
            targetVolume.ouvragesAControler = targetVolume.ouvragesAControler.concat(volumeActuel.ouvragesAControler);
            targetVolume.commentairesId = targetVolume.commentairesId.concat(volumeActuel.commentairesId);
            targetVolume.valeursParametres = Object.assign(targetVolume.valeursParametres, volumeActuel.valeursParametres);

            const currentNiveau = this.findNiveauForVolume(bien, volumeActuel);
            this.removeVolumeFromNiveau(currentNiveau, volumeActuel);

            targetNiveau.volumes.forEach((vol) => (vol.nom = this.descriptionBienService.generateVolumeName(vol, targetNiveau)));
            return this.descriptionBienService.editVolumeInMap(bien, targetNiveau, targetVolume).pipe(
                switchMap(() => {
                    return this.referenceService.findAllTypesVolumes();
                }),
                tap((typeVolume: TypeVolume[]) =>
                    diagnostics.forEach((diag) =>
                        this.diagnosticHandlerService.getTypePrestationService(diag.typePrestation).mergeVolume(
                            diag,
                            volumeActuel,
                            targetVolume,
                            bien,
                            typeVolume.find((type) => type.id === volumeActuel.usageId)
                        )
                    )
                ),
                map(() => [interventionUpdated, diagnostics])
            );
        });
    }

    private updateAllDiagnosticsAndIntervention(
        intervention: Intervention,
        worker: (intervention: Intervention, diagnostics: Diagnostic[]) => Observable<[Intervention, Diagnostic[]]>
    ) {
        return this.diagnosticService.getAllDiagnosticsForCurrentIntervention(intervention).pipe(
            map((diagnostics) => diagnostics.filter((it) => it.etat !== EtatDiagnostic.ANNULE)),
            switchMap((diagnostics) => worker(intervention, diagnostics)),
            switchMap(([intervention, diagnostics]) =>
                combineLatest([
                    this.interventionService.updateIntervention(intervention),
                    ...diagnostics.map((diag) => this.diagnosticService.upsert(intervention, diag)),
                ])
            ),
            tap(() => this.diagnosticService.reloadCurrentDiagnostic()),
            tap(() => this.interventionService.reloadCurrentIntervention())
        );
    }
    private findNiveauForVolume(bien: Bien, volume: Volume) {
        return bien.description.find((it) => it.volumes.some((vol) => vol.id === volume.id));
    }
    private removeVolumeFromNiveau(niveauActuel: Niveau, volume: Volume) {
        const index = niveauActuel.volumes.indexOf(volume);
        if (index > -1) {
            niveauActuel.volumes.splice(index, 1);
            niveauActuel.volumes.forEach((vol) => (vol.nom = this.descriptionBienService.generateVolumeName(vol, niveauActuel)));
        }
    }

    private deleteNiveau(bienDescription: Niveau[], currentNiveau: Niveau) {
        const index = bienDescription.indexOf(currentNiveau);
        if (index > -1) {
            bienDescription.splice(index, 1);
        }
    }
}

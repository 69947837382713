/**
 * Bien.
 */
import { Pair } from './shared.model';
import { Adresse } from './adresse.model';
import { MongoUtils } from 'src/app/commons-lib';
import { DrawingPicture } from '@acenv/cnmap-angular-editor-lib';
import { Contact } from './contact.model';
import * as moment from 'moment';
import { DATE_FORMAT } from '../shared/constants/cndiag.constants';
import { TypePrestation } from './type-prestation.model';
import { Equipement } from './equipement.model';
import { OuvrageAControler } from './categorie-ouvrage.model';

export enum ViewMode {
    PLAN = 'PLAN',
    LIST = 'LIST',
}

export class Bien {
    id: string;

    /**
     * Type de bien
     */
    idTypeBien: string;

    /**
     * Précision sur le type de bien
     */
    sousType?: string;

    /**
     * Id du fichier contenant l'image du bien
     */
    idFichierImage: string;

    /**
     * Id du fichier contenant l'image satelitaire du bien
     * Ceci est un teste, en cas de bug, retirer
     */
    idFichierImageSat: string;

    /**
     * Désignation
     */
    nom?: string;

    /**
     * Adresse
     */
    adresse: Adresse;

    /**
     * Adresse à plat du type voie; cmpl1; cmpl2; CP; ville utilisée pour filtrer dans les tableaux
     */
    adresseStringPourFiltre: string;

    /**
     * Référence du bien (client)
     */
    refBien?: string;
    /**
     * Bâtiment
     */
    batiment?: string;

    /**
     * Nombre d'entrées
     */
    nombreEntrees?: number;

    /**
     * Escalier / N° de porte
     */
    escalierNumPorte?: string;

    /**
     * Situation (niveau / étage)
     */
    situation?: string;

    /**
     * Nombre de niveaux positifs
     */
    nbNiveauxPositifs?: number;

    /**
     * Nombre de niveaux négatifs
     */
    nbNiveauxNegatifs?: number;

    /**
     * Nombre de pièces principales
     */
    nbPiecesPrincipales?: string;

    /**
     * Nombre de cages d'escalier ?
     */
    nbCagesEscalier?: number;

    /**
     * Année de construction
     */
    anneeConstruction?: number;

    /**
     * Mois de construction
     */
    moisConstruction?: string;

    /**
     * Numéro de lot (si copropriété)
     */
    numeroLot?: string;

    /**
     * Numéro de parcelle (si copropriété)
     */
    numeroParcelle?: string;

    /**
     * Référence cadastre
     */
    referenceCadastre?: string;

    /**
     * Coordonnées GPS
     */
    coordonnees: Pair<number, number>;

    /**
     * Surface
     */
    surface?: number;

    /**
     * Commentaires
     */
    commentairesId: string[] = [];

    /**
     * Liste de biens annexes
     */
    annexes: Bien[] = [];

    /**
     * Description du bien
     */
    description: Niveau[] = [];

    /**
     * Nécéssité d'informations pour valider le bien
     */
    sansDescription: boolean = false;

    /**
     * Types d'éléments à contrôler propres au bien
     */
    equipements: Equipement[] = [];

    /**
     * Plan issu de l'éditeur
     */
    jsonPlan: string;

    backgroundMaps: DrawingPicture[] = [];

    pictures: DrawingPicture[] = [];

    /**
     * True si l'utilsateur a valider au moins 1 fois le bien
     * False sinon
     */
    estValider = false;

    categorieERP?: string = null;
    nbLots?: number = null;
    typeChauffage?: string = null;
    modeChauffage?: string = null;
    contactProprietaire?: Contact = null;
    /**
     * Propriétaire à plat du type 'nom prenom' utilisé pour filtrer dans les tableaux
     */
    contactProprietaireString?: string = null;
    idContactProprietaire?: string = null;
    idSalesforce?: string = null;

    dateModificationBien: string;

    /**
     * Défini si l'utilisateur peut mettre à jour le bien par rapport
     * à une version existante plus récentes dans la table RelationInterventionBien
     */
    hasNewVersionInRelationInterventionBien = false;

    typologieSurface?: string = null;

    constructor() {
        this.adresse = new Adresse();
        this.coordonnees = { first: null, second: null };
        this.dateModificationBien = moment().format(DATE_FORMAT);
    }

    // TODO typesElementsAControler => enlever
    typesElementsAControler: any[];
}

export class BackgroundMapBien extends DrawingPicture {
    /** Id du bien auquel le fond de carte est associé, sert pour le mode hors-ligne */
    public bienId?: string;
}

/**
 * Niveau, possédant des types volume
 */
export class Niveau {
    /**
     * Identifiant
     */
    id: string;

    /**
     * Index du niveau
     */
    index: number;

    /**
     * Nom de l'étage
     */
    nom: string = null;

    /**
     * Volumes du niveau
     */
    volumes: Volume[] = [];

    /**
     * Id du storey si plan associé
     */
    storeyId: string = null;

    constructor() {
        // Génère un id aléatoire lorsqu'on instancie un niveau
        this.id = MongoUtils.generateObjectId();
    }
}

/**
 * Volume d'un bien
 */
export class Volume {
    /**
     * Identifiant
     */
    id: string;

    /**
     * Désignation
     */
    nom: string;

    /**
     * Un volume caché est créé à la création d'un niveau.
     * Il permet de déposer des éléments indirectement dans le niveau
     */
    volumeCache = false;

    /**
     * Éléments à contrôler du volume
     */
    equipements: Equipement[] = [];

    /**
     * Ouvrages à contrôler du volume
     */
    ouvragesAControler: OuvrageAControler[] = [];

    /**
     * Valeurs des paramètres
     */
    valeursParametres: any = {};

    /**
     * Liste de commentaires liée à ce volume
     */
    commentairesId: string[] = [];

    /**
     * Id du space si plan associé
     */
    spaceId: string = null;

    /**
     * Id de l'usage de la pièce si usage spécifié
     */
    usageId: string = null;

    /**
     * justifié
     */
    justifie: boolean = false;

    /**
     * Index de l'espace dans cnmap
     */
    indexEspace: number = null;

    /**
     * Préfixe à ajouter au nom du volume
     */
    prefixeNumerotation: string = undefined;

    /**
     * Numérotation du volume, à partir de 0 (la vraie valeur = numerotation + numerotationDebut)
     */
    numerotation: number = undefined;

    /**
     * Début de numérotation, 1
     */
    numerotationDebut: number = undefined;

    /**
     * Nom de base du volume
     */
    nomBase: string = null;

    constructor() {
        // Génère un id aléatoire lorsqu'on instancie un volume
        this.id = MongoUtils.generateObjectId();
    }

    // TODO remove
    elementsAControler: any[];
}

/**
 * Élément à contrôler
 */
export class ElementAControler {
    /**
     * Identifiant
     */
    id: string;

    /**
     * Désignation
     */
    nom: string = null;

    /**
     * Code BIM
     */
    codeBim: string;

    /**
     * Valeurs des paramètres
     */
    valeursParametres: any = {};

    /**
     * Id du object_instance si plan associé
     */
    objectId: string = null;

    /**
     * Types de prestations associés à l'élément
     */
    typesPrestationsAssocie: TypePrestation[] = [];

    constructor() {
        // Génère un id aléatoire lorsqu'on instancie un élément à contrôler
        this.id = MongoUtils.generateObjectId();
    }

    // TODO remove
    idTypeElementAControler: string;
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, MongoUtils, NotificationService } from 'src/app/commons-lib';
import { combineLatest, forkJoin, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EditMode } from 'src/app/model/edit-mode.model';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { URL_GESTION_CATEGORIE_OUVRAGE_EDIT, URL_GESTION_CATEGORIES_OUVRAGES } from 'src/app/shared/constants/url.constants';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import {
    CategorieOuvrage,
    Couleur,
    Revetement,
    Substrat,
    TypeIncrementation,
    TypeParametreCategorieOuvrage,
    typeParametreCategorieOuvrageLabel,
} from '../../../../../model/categorie-ouvrage.model';
import { ReferenceService } from '../../../../../services/reference.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import {
    EditValeursParametresData,
    EditValeursParametresModalComponent,
} from './edit-valeurs-parametres-modal/edit-valeurs-parametres-modal.component';

export const BRUT = 'BRUT';

@Component({
    selector: 'app-creation-categorie-ouvrage',
    templateUrl: './creation-categorie-ouvrage.component.html',
    styleUrls: ['./creation-categorie-ouvrage.component.scss'],
})
export class CreationCategorieOuvrageComponent extends BaseComponent implements OnInit {
    categorieOuvrage: CategorieOuvrage = new CategorieOuvrage();
    categorieOuvrageInitiale: CategorieOuvrage = new CategorieOuvrage();

    mode: EditMode = 'CREATE';
    isReadOnly = false;

    formCategorieOuvrage: FormGroup;

    listTypeIncrementation: any[];

    get formParametres(): FormArray {
        return this.formCategorieOuvrage.get('parametres') as FormArray;
    }

    get formRevetements(): FormArray {
        return this.formCategorieOuvrage.get('revetements') as FormArray;
    }

    get formSubstrats(): FormArray {
        return this.formCategorieOuvrage.get('substrats') as FormArray;
    }

    get formCouleurs(): FormArray {
        return this.formCategorieOuvrage.get('couleurs') as FormArray;
    }

    mapCategoriesOuvrages: Map<string, CategorieOuvrage> = new Map<string, CategorieOuvrage>();
    typeParametreCategorieOuvrage;

    mapRevetements: Map<string, Revetement[]>;
    mapSubstrats: Map<string, Substrat[]>;
    mapCouleurs: Map<string, Couleur[]>;

    BRUT = BRUT;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly referenceApiService: ReferenceApiService,
        private readonly referenceService: ReferenceService,
        private readonly notificationService: NotificationService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.formCategorieOuvrage = this.formBuilder.group({
            nom: ['', Validators.required],
            nomOuvrage: [''],
            typeIncrementation: [''],
            incrementationGroup: [''],
            etatCategorieOuvrage: [EtatWorkflow.INACTIF, Validators.required],
            code: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (
                            control.value &&
                            ValidatorUtils.checkAlreadyExist(
                                this.formCategorieOuvrage.controls.code.value,
                                this.mode !== 'EDIT',
                                this.categorieOuvrageInitiale.code,
                                Array.from(this.mapCategoriesOuvrages.values())?.map((it: CategorieOuvrage) => it.code)
                            )
                        ) {
                            return { erreurNomEquipement: true };
                        }
                        return null;
                    },
                ],
            ],
            lienCodeParent: [''],
            parametres: this.formBuilder.array([]),
            activatedRevetements: false,
            revetements: this.formBuilder.array([]),
            activatedSubstrats: false,
            substrats: this.formBuilder.array([]),
            activatedCouleurs: false,
            couleurs: this.formBuilder.array([]),
        });
        this.typeParametreCategorieOuvrage = Object.keys(TypeParametreCategorieOuvrage).map((it) => {
            return {
                label: typeParametreCategorieOuvrageLabel[it],
                value: it,
            };
        });
        this.listTypeIncrementation = Object.keys(TypeIncrementation).map((it) => {
            return {
                label: TypeIncrementation[it],
                value: it,
            };
        });

        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        const idCategorieOuvrage = params.get('idCategorieOuvrage');
                        if (idCategorieOuvrage) {
                            this.mode = data.duplicate ? 'DUPLICATE' : 'EDIT';
                            this.isReadOnly = data.consulter;
                            return this.referenceApiService.findOneCategorieOuvrage(idCategorieOuvrage);
                        }
                        return of(null);
                    }),
                    switchMap((currentCategorieOuvrage) =>
                        forkJoin([of(currentCategorieOuvrage), this.referenceService.forceReloadCategoriesOuvrages()])
                    )
                )
            )
            .subscribe(([currentCategorieOuvrage, listeCategoriesOuvrages]) => {
                if (currentCategorieOuvrage) {
                    this.categorieOuvrage = { ...currentCategorieOuvrage };
                    this.categorieOuvrageInitiale = { ...currentCategorieOuvrage };
                }

                this.mapRevetements = new Map<string, Revetement[]>();
                this.mapSubstrats = new Map<string, Substrat[]>();
                this.mapCouleurs = new Map<string, Couleur[]>();
                listeCategoriesOuvrages
                    .filter((it) => !currentCategorieOuvrage || it.code != currentCategorieOuvrage.code)
                    .forEach((it) => {
                        this.mapCategoriesOuvrages.set(it.code, it);
                        if (it.revetements && it.revetements.length > 0) {
                            this.mapRevetements.set(it.code, it.revetements);
                        }
                        if (it.substrats && it.substrats.length > 0) {
                            this.mapSubstrats.set(it.code, it.substrats);
                        }
                        if (it.couleurs && it.couleurs.length > 0) {
                            this.mapCouleurs.set(it.code, it.couleurs);
                        }
                    });
                if (this.mode !== 'CREATE') {
                    this.populateForm();
                }
            });
    }

    private populateForm() {
        const revetements = this.categorieOuvrage.revetements ?? [];
        if (!revetements.find((it) => it.code === BRUT) && this.categorieOuvrage.lienCodeParent) {
            revetements.push({
                id: '',
                nom: 'Brut',
                code: BRUT,
            });
        }
        this.categorieOuvrage.revetements = revetements;
        this.categorieOuvrage.couleurs = this.categorieOuvrage.couleurs ?? [];
        this.categorieOuvrage.substrats = this.categorieOuvrage.substrats ?? [];
        this.formCategorieOuvrage.patchValue({
            nom: this.categorieOuvrage.nom,
            nomOuvrage: this.categorieOuvrage.nomOuvrage,
            typeIncrementation: this.categorieOuvrage.typeIncrementation,
            incrementationGroup: this.categorieOuvrage.incrementationGroup,
            etatCategorieOuvrage: this.categorieOuvrage.etatCategorieOuvrage,
            code: this.categorieOuvrage.code,
            lienCodeParent: this.categorieOuvrage.lienCodeParent,
            parametres: this.categorieOuvrage.parametres.map((it) => {
                const param = this.formBuilder.group({
                    id: it.id,
                    label: it.label,
                    code: it.code,
                    type: it.typeParametre,
                    values: this.formBuilder.array(it.values ?? []),
                });
                this.formParametres.push(param);
                return param;
            }),
            activatedRevetements: this.categorieOuvrage.activatedRevetements,
            revetements: this.categorieOuvrage.revetements.map((it) => {
                const revetement = this.formBuilder.group({
                    id: it.id,
                    nom: it.nom,
                    code: it.code,
                });
                this.formRevetements.push(revetement);
                return revetement;
            }),
            activatedSubstrats: this.categorieOuvrage.activatedSubstrats,
            substrats: this.categorieOuvrage.substrats.map((it) => {
                const substrat = this.formBuilder.group({
                    id: it.id,
                    nom: it.nom,
                    code: it.code,
                });
                this.formSubstrats.push(substrat);
                return substrat;
            }),
            activatedCouleurs: this.categorieOuvrage.activatedCouleurs,
            couleurs: this.categorieOuvrage.couleurs.map((it) => {
                const couleur = this.formBuilder.group({
                    id: it.id,
                    nom: it.nom,
                    valeur: it.valeur,
                });
                this.formCouleurs.push(couleur);
                return couleur;
            }),
        });
    }

    handleCopierRevetement(revetementKey) {
        this.categorieOuvrage.revetements = this.mapRevetements.get(revetementKey);
        this.formRevetements.clear();
        this.formCategorieOuvrage.patchValue({
            revetements: this.categorieOuvrage.revetements.map((it) => {
                const revetement = this.formBuilder.group({
                    nom: it.nom,
                    code: it.code,
                });
                this.formRevetements.push(revetement);
                return revetement;
            }),
        });
    }

    handleCopierSubstrat(substratKey) {
        this.categorieOuvrage.substrats = this.mapSubstrats.get(substratKey);
        this.formSubstrats.clear();
        this.formCategorieOuvrage.patchValue({
            substrats: this.categorieOuvrage.substrats.map((it) => {
                const substrat = this.formBuilder.group({
                    nom: it.nom,
                    code: it.code,
                });
                this.formSubstrats.push(substrat);
                return substrat;
            }),
        });
    }

    handleCopierCouleur(couleurKey) {
        this.categorieOuvrage.couleurs = this.mapCouleurs.get(couleurKey);
        this.formCouleurs.clear();
        this.formCategorieOuvrage.patchValue({
            couleurs: this.categorieOuvrage.couleurs.map((it) => {
                const couleur = this.formBuilder.group({
                    nom: it.nom,
                    valeur: it.valeur,
                });
                this.formCouleurs.push(couleur);
                return couleur;
            }),
        });
    }

    validerCategorieOuvrage() {
        this.categorieOuvrage.nom = this.formCategorieOuvrage.value.nom;
        this.categorieOuvrage.nomOuvrage = this.formCategorieOuvrage.value.nomOuvrage;
        this.categorieOuvrage.typeIncrementation = this.formCategorieOuvrage.value.typeIncrementation;
        this.categorieOuvrage.incrementationGroup = !!this.formCategorieOuvrage.value.incrementationGroup
            ? this.formCategorieOuvrage.value.incrementationGroup
            : this.formCategorieOuvrage.value.code;
        this.categorieOuvrage.code = this.formCategorieOuvrage.value.code;
        this.categorieOuvrage.lienCodeParent = this.formCategorieOuvrage.value.lienCodeParent;
        this.categorieOuvrage.etatCategorieOuvrage = this.formCategorieOuvrage.value.etatCategorieOuvrage;
        this.categorieOuvrage.parametres = this.formCategorieOuvrage.value.parametres.map((it) => {
            return {
                id: it.id,
                label: it.label,
                code: it.code,
                typeParametre: it.type,
                values: it.values,
            };
        });
        this.categorieOuvrage.activatedRevetements = this.formCategorieOuvrage.value.activatedRevetements;
        this.categorieOuvrage.revetements = this.formCategorieOuvrage.value.revetements.map((it) => {
            return {
                id: it.id ? it.id : MongoUtils.generateObjectId(),
                nom: it.nom,
                code: it.code,
            };
        });
        this.categorieOuvrage.activatedSubstrats = this.formCategorieOuvrage.value.activatedSubstrats;
        this.categorieOuvrage.substrats = this.formCategorieOuvrage.value.substrats.map((it) => {
            return {
                id: it.id ? it.id : MongoUtils.generateObjectId(),
                nom: it.nom,
                code: it.code,
            };
        });
        this.categorieOuvrage.activatedCouleurs = this.formCategorieOuvrage.value.activatedCouleurs;
        this.categorieOuvrage.couleurs = this.formCategorieOuvrage.value.couleurs.map((it) => {
            return {
                id: it.id ? it.id : MongoUtils.generateObjectId(),
                nom: it.nom,
                valeur: it.valeur,
            };
        });

        if (this.mode === 'EDIT') {
            this.categorieOuvrage.id = this.categorieOuvrageInitiale.id;
            this.referenceApiService.updateCategorieOuvrage(this.categorieOuvrage).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    this.notificationService.success("L'équipement a été modifié");
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        } else {
            this.categorieOuvrage.id = MongoUtils.generateObjectId();
            this.referenceApiService.createCategorieOuvrage(this.categorieOuvrage).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    const message = this.mode === 'CREATE' ? "L'équipement a été créé" : "L'équipement a été dupliqué";
                    this.notificationService.success(message);
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        }
    }

    addParametre() {
        const formParametre = this.formBuilder.group({
            id: '',
            label: '',
            code: '',
            type: null,
            values: this.formBuilder.array([]),
        });

        this.formParametres.push(formParametre);
    }

    addRevetement() {
        const formRevetement = this.formBuilder.group({
            id: '',
            nom: '',
            code: '',
        });

        this.formRevetements.push(formRevetement);
    }

    deleteSubstrat(index: number) {
        this.formSubstrats.removeAt(index);
    }

    addSubstrat() {
        const formSubstrat = this.formBuilder.group({
            id: '',
            nom: '',
            code: '',
        });

        this.formSubstrats.push(formSubstrat);
    }

    deleteRevetement(index: number) {
        this.formRevetements.removeAt(index);
    }

    addCouleur() {
        const formCouleur = this.formBuilder.group({
            id: '',
            nom: '',
            valeur: '',
        });

        this.formCouleurs.push(formCouleur);
    }

    deleteCouleur(index: number) {
        this.formCouleurs.removeAt(index);
    }

    deleteParametre(index: number) {
        this.formParametres.removeAt(index);
    }

    dropParametre(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.formParametres.value, event.previousIndex, event.currentIndex);
        moveItemInArray(this.formParametres.controls, event.previousIndex, event.currentIndex);
    }

    editer() {
        this.router.navigate([URL_GESTION_CATEGORIE_OUVRAGE_EDIT, this.categorieOuvrage.id]);
    }

    back() {
        this.router.navigate([URL_GESTION_CATEGORIES_OUVRAGES]);
    }

    readonly TypeParametreCategorieOuvrage = TypeParametreCategorieOuvrage;

    configureValues(valuesControl: AbstractControl) {
        const values = valuesControl as FormArray;
        this.matDialog
            .open<EditValeursParametresModalComponent, EditValeursParametresData, { values: string[] } | undefined>(
                EditValeursParametresModalComponent,
                { data: { placeholder: '', readOnly: this.isReadOnly, values: values.value as string[] } }
            )
            .afterClosed()
            .pipe(
                filter((it) => !!it.values),
                tap((it) => {
                    values.clear();
                    it.values.forEach((v) => values.push(this.formBuilder.control(v)));
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe();
    }
}

import { Injectable } from '@angular/core';
import { OuvrageAControler } from '../../../../model/categorie-ouvrage.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { ConfirmationService } from '../../../../lib/confirmation/confirmation.service';
import { confirm } from '../../../../utils/confirm.utils';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

export class OuvrageCopyContext {
    ouvrage?: OuvrageAControler;
    selectedParties?: string[];
    volume?: Volume;
    niveau?: Niveau;
    bien?: Bien;
    enabled?: boolean;
    onlyRevetement?: boolean;
}

/**
 * Copier les paramètres et parties d'ouvrage d'un ouvrage vers d'autres ouvrages (seulement les mêmes types)
 * @param ctx le contexte de copie (ouvrage, niveau, bien)
 * @param ouvragesDestination la liste des ouvrages de destination
 */
function copyProprietesOuvrages(ctx: OuvrageCopyContext, ouvragesDestination: OuvrageAControler[]) {
    const { bien, ouvrage, niveau } = ctx;
    ouvragesDestination
        // seulement les mêmes catégories
        .filter((it) => it.codeCategorieOuvrage === ctx.ouvrage.codeCategorieOuvrage)
        .forEach((ouvrageDestination) => {
            ouvrageDestination.parametres = { ...ouvrage.parametres };
            ouvrageDestination.partiesOuvrages = [
                ...ouvrageDestination.partiesOuvrages,
                ...ouvrage.partiesOuvrages
                    .filter((it) => ctx.selectedParties.some((iit) => iit === it.id))
                    .map((it) => {
                        const { id, ...partieOuvrageWithoutId } = it;
                        return { ...new OuvrageAControler(), ...partieOuvrageWithoutId };
                    }),
            ];
        });
}

/**
 * Copier propriétés depuis le premier
 */
function copyProperties(ctx: OuvrageCopyContext, ouvragesDestination: OuvrageAControler[]) {
    const ouvrageAControler = ctx.ouvrage?.partiesOuvrages?.find((it) => it.id === ctx.selectedParties?.[0]);
    if (ouvrageAControler) {
        ouvragesDestination
            .flatMap((it) => it.partiesOuvrages || [])
            .forEach((it) => {
                const { codeRevetement, codeSubstrat, valeurCouleur, valeurCaracteristiqueCouleur } = ouvrageAControler;
                Object.assign(it, { codeRevetement, codeSubstrat, valeurCouleur, valeurCaracteristiqueCouleur });
            });
    }
}

@Injectable({ providedIn: 'root' })
export class DescriptionBienModalOuvragesService {
    constructor(private confirmationService: ConfirmationService) {}

    private _selectedOuvrageToCopyContext = new BehaviorSubject<OuvrageCopyContext>({
        enabled: false,
        onlyRevetement: false,
    });

    public readonly selectedOuvrageToCopyContext = this._selectedOuvrageToCopyContext.asObservable();

    public selectOuvrageToCopy(ouvrageToCopy: OuvrageCopyContext) {
        this._selectedOuvrageToCopyContext.next({ ...ouvrageToCopy, enabled: true, onlyRevetement: false });
    }

    public selectPartieToCopy(ouvrageToCopy: OuvrageCopyContext) {
        this._selectedOuvrageToCopyContext.next({ ...ouvrageToCopy, enabled: false, onlyRevetement: true });
    }

    public cancelCopy() {
        this._selectedOuvrageToCopyContext.next({ enabled: false, onlyRevetement: false });
    }

    copierProprietesOuvrages(ouvragesDestination: OuvrageAControler[]): Observable<boolean> {
        return this.selectedOuvrageToCopyContext.pipe(
            take(1),
            map((ctx) => {
                const filteredOuvragesDestination = (
                    ctx.onlyRevetement ? ouvragesDestination.flatMap((it) => it.partiesOuvrages) : ouvragesDestination
                ).filter((it) => !it.objectId);
                const containsOuvragesPlan = ouvragesDestination.length > filteredOuvragesDestination.length;
                const msgPlan = containsOuvragesPlan ? '(Seulement les ouvrages hors plan seront considérés) ' : '';
                return {
                    ...ctx,
                    msgPlan,
                };
            }),
            switchMap((ctx) =>
                confirm(
                    this.confirmationService,
                    ctx.onlyRevetement
                        ? `Êtes-vous sûr de vouloir coller les paramètres de revêtement / substrat ${ctx.msgPlan}?`
                        : `Êtes-vous sûr de vouloir copier les parties d'ouvrages sélectionnées de '${ctx.ouvrage?.nom}' ? (Seulement les parties d'ouvrage du même type d'ouvrage seront copiées.) ${ctx.msgPlan}`
                ).pipe(
                    tap((confirmed) => {
                        if (confirmed) {
                            if (ctx.onlyRevetement) {
                                copyProperties(ctx, ouvragesDestination);
                            } else {
                                copyProprietesOuvrages(ctx, ouvragesDestination);
                            }
                        }
                    })
                )
            ),
            tap((confirmed) => confirmed && this.cancelCopy())
        );
    }
}

import { Equipement } from '../model/equipement.model';
import { Volume } from '../model/bien.model';

export class ListUtils {
    /**
     * Création d'un nom unique en fonction de la liste de noms
     * @param namesAlreadyPresents Liste des noms déjà présents
     * @param name Nom à tester et incrémenter en fonction
     */
    static createUniqueName(namesAlreadyPresents: string[], name: string): string {
        // Vérifie si le nom est déjà présent dans la liste de noms déjà présents
        const isInVolumesNamesAlreadyPresents =
            namesAlreadyPresents
                .map((x) => x.split(/(\d+)/)[0].trim().toLowerCase())
                .filter((it) => it === name.split(/(\d+)/)[0].trim().toLowerCase()).length > 0;

        let nameReturn = name;

        // Si il est déjà présent dans le tableau : générer des noms jusqu'à en trouver un de disponible.
        if (isInVolumesNamesAlreadyPresents) {
            let i = 2;
            let newName = `${name} ${i}`;
            while (namesAlreadyPresents.includes(newName)) {
                newName = `${name} ${i}`;
                i++;
            }
            nameReturn = newName;
        }

        return nameReturn;
    }

    /**
     * Création d'un nom unique en fonction de la liste de noms
     * @param elementAlreadyPresents Liste des elements déjà présents
     * @param element Element à tester et incrémenter en fonction
     */
    static createUniqueNameElementAControler(elementAlreadyPresents: Equipement[], element: Equipement): string {
        if (!element) {
            return null;
        }

        let nameReturn = '';
        nameReturn = element.name + ' 1';

        // Vérifie si le nom est déjà présent dans la liste de noms déjà présents
        let isInElementAlreadyPresents = false;

        isInElementAlreadyPresents =
            (elementAlreadyPresents as Equipement[])
                .map((elementTemp) => elementTemp.productTypeCodeBim)
                .filter((it) => it === (element as Equipement).productTypeCodeBim).length > 0;

        // Si il est déjà présent dans le tableau : générer des noms jusqu'à en trouver un de disponible.
        if (isInElementAlreadyPresents) {
            let i = 1;

            let newName = `${element.name} ${i}`;
            while ((elementAlreadyPresents as Equipement[]).find((typeElementTemp) => typeElementTemp.name === newName) != undefined) {
                newName = `${element.name} ${i}`;
                i++;
            }
            nameReturn = newName;
        }
        return nameReturn;
    }
}

export function isEmpty<T>(list: T[]) {
    return !list || !list.length;
}

export function wherePropEquals<T, V>(prop: string, val: any) {
    return (obj: T) => obj[prop] === val;
}

/**
 * A type that extracts keys of an object where the values are strictly of type `number`.
 *
 * @template T - The type of the object.
 * @template P - The key type.
 */
type KeysOfType<T, P> = Extract<keyof T, { [K in keyof T]: T[K] extends P ? (P extends T[K] ? K : never) : never }[keyof T]>;

/**
 * Returns the maximum value of a specified numeric property from an array of objects.
 *
 * @template T - The type of objects in the array.
 * @param {T[]} array - The array of objects to search through.
 * @param {NumberKeys<T>} propertyName - The property name whose maximum value is to be found.
 * @returns {number} The maximum value of the specified property, or 0 if none is found.
 *
 * @example
 * const volumes = [
 *     { indexEspace: 10 },
 *     { indexEspace: 20 },
 *     { indexEspace: 30 }
 * ];
 * const maxIndexEspace = max(volumes, 'indexEspace');
 * console.log(maxIndexEspace); // Output: 30
 */
export function max<T>(array: T[], propertyName: KeysOfType<T, number>): number {
    const maxValue = array
        .filter((item) => isANumber(item[propertyName]))
        .map((item) => {
            const itemElement = item[propertyName] as unknown as number;
            return itemElement;
        })
        .reduce((a, b) => Math.max(a, b), Number.NEGATIVE_INFINITY);
    return maxValue > Number.NEGATIVE_INFINITY ? maxValue : undefined;
}

// Helper function to check if a value is a number
function isANumber(value: any): boolean {
    return typeof value === 'number' && !isNaN(value);
}

import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '../../../../services/report.service';
import { InterventionReportData, ReportContent } from '../../../../model/rapport.model';
import { AuthenticationStore, BaseComponent, NotificationService } from 'src/app/commons-lib';
import { Prestation } from '../../../../model/prestation.model';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { NavigationBarsService } from '../../../../services/navigation-bars.service';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { InterventionService } from '../../../../services/intervention.service';
import { EtatIntervention, Intervention } from 'src/app/model/intervention.model';
import { BON_COMMANDE_ANALYSE, DOCS, DOCS_DIAG, RAPPORT, SAVE_BON_COMMANDE, SAVE_RAPPORT } from 'src/app/shared/constants/names.step.constants';
import { MatDialog } from '@angular/material/dialog';
import { ReportValidationModalComponent } from '../report-validation-modal/report-validation-modal.component';
import { UserInformationApiService } from '../../../../services/user-information-api.service';
import { Diagnostic, EtatDiagnostic } from 'src/app/model/diagnostic.model';
import { combineLatest, Observable, of } from 'rxjs';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { TypeReport } from 'src/app/model/reference-prestation.model';
import { UserInformation } from 'src/app/model/user-wizy.model';
import { ActivatedRoute, Router } from '@angular/router';
import { URL_MON_COMPTE_SIGNATURE, URL_TABLEAU_DE_BORD } from 'src/app/shared/constants/url.constants';
import { CheckValidityTabService } from 'src/app/services/check-validity-tab.service';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { ExportSvgService } from '../../../shared/map/export-svg-dialog/shared/services/export-svg.service';
import { TypePrestationEnum } from '../../../../model/type-prestation.model';
import { ScreenshotSvg } from '../../../../model/screenshot-svg.model';

@Component({
    selector: 'app-report-content',
    templateUrl: './report-content.component.html',
    styleUrls: ['./report-content.component.scss'],
})
export class ReportContentComponent extends BaseComponent implements OnInit {
    @Input()
    prestation: Prestation;

    @Input()
    typeReport: TypeReport;

    reportContent: ReportContent;
    diagnostic: Diagnostic;
    intervention: Intervention;
    userInformation: UserInformation;
    hasSignature: boolean;
    interReportData: InterventionReportData;
    screenshotsWithSvg: { screenshotSvg: ScreenshotSvg; svgPlan: string }[];

    constructor(
        private readonly matDialog: MatDialog,
        private readonly reportService: ReportService,
        private readonly interventionService: InterventionService,
        private readonly notificationService: NotificationService,
        private readonly navigationBarsService: NavigationBarsService,
        private readonly diagnosticService: DiagnosticService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly userInformationApiService: UserInformationApiService,
        private readonly authenticationStore: AuthenticationStore,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly checkValidityTabService: CheckValidityTabService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly exportSvgService: ExportSvgService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.prestation) {
            this.cnSpinnerService
                .withSpinner(
                    combineLatest([
                        this.authenticationStore
                            .getCurrentUser()
                            .pipe(switchMap((user) => this.userInformationApiService.getUserInformationByUserId(user.id))),
                        this.interventionService.getCurrentIntervention(),
                        this.diagnosticService.getCurrentDiagnostic(),
                    ])
                )
                .pipe(
                    switchMap(([userInformation, intervention, diagnostic]) => {
                        this.userInformation = userInformation;
                        this.hasSignature = this.userInformation && this.userInformation.fichierSignatureId !== undefined;
                        this.diagnostic = diagnostic;
                        this.intervention = intervention;
                        // Rafraichissement des exports de plans si présents
                        return this.exportSvgService.refreshScreenshots(this.intervention, this.diagnostic);
                    }),
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe((screenshotsWithSvg) => {
                    this.screenshotsWithSvg = screenshotsWithSvg;
                    this.reportContent = this.reportService.getReportContent(this.prestation, this.typeReport);
                    switch (this.typeReport) {
                        case TypeReport.REPORT:
                            this.interReportData = this.reportService.getInterventionReportData(
                                this.prestation,
                                this.intervention,
                                this.diagnostic,
                                this.userInformation
                            );
                            break;
                        case TypeReport.BON_COMMANDE:
                            this.interReportData = this.reportService.getInterventionBonCommandeData(
                                this.intervention,
                                this.diagnostic,
                                this.userInformation
                            );
                            if (
                                this.diagnostic.listeBonCommande.length === 0 ||
                                (this.diagnostic.listeBonCommande.length && !this.diagnostic.listeBonCommande[0].signed)
                            ) {
                                const bonCommande: BonCommandeAnalyseAdmin = this.reportService.generateDiagnosticBonCommande(
                                    this.intervention,
                                    this.diagnostic,
                                    this.interReportData
                                );
                                this.diagnostic.listeBonCommande = [bonCommande];
                            }

                            this.diagnosticService.upsert(this.intervention, this.diagnostic).subscribe();
                            break;
                    }
                });
        }
        this.navigationBarsService
            .listenCustomAction()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((code) => {
                if (code === SAVE_RAPPORT) {
                    this.saveRapportData();
                }
                if (code === SAVE_BON_COMMANDE) {
                    this.saveBonCommandeData();
                }
            });
    }

    /**
     * Sauvegarde dans le diagnostic les datas du rapport
     */
    saveRapportData() {
        combineLatest([this.interventionService.getCurrentIntervention(), this.diagnosticService.getCurrentDiagnostic()])
            .pipe(
                take(1),
                switchMap(([intervention, diagnostic]) => {
                    const diagnosticEtat = diagnostic.etatProgressions;
                    const invalids = diagnosticEtat.concat(intervention.etatProgressions).filter((it) => ['INVALID', 'UNVERIFIED'].includes(it.etat));
                    const onlyOngletDocsIsInvalid = invalids.length === 1 && [DOCS, DOCS_DIAG].includes(invalids[0].code);

                    /* const isDocumentsObligatoireManquantDiagnostic =
                        this.documentsService.isDocumentsObligatoireManquant(intervention, diagnostic); */
                    if (invalids.length === 0 || onlyOngletDocsIsInvalid) {
                        return this.matDialog
                            .open(ReportValidationModalComponent, {
                                data: {
                                    docsIsInvalid: false,
                                    hasSignature: this.hasSignature,
                                },
                            })
                            .afterClosed()
                            .pipe(
                                tap((result) => {
                                    if (result?.etatDiagnosticSelection === EtatDiagnostic.FINI) {
                                        this.generateEstimatif(diagnostic);
                                    }
                                }),
                                switchMap((result) => combineLatest([of(result), of(intervention), of(diagnostic)]))
                            );
                    } else {
                        this.notificationService.error({
                            message: `Tous les onglets de l'intervention et du diagnostic n'ont pas été remplis`,
                            showCloseButton: false,
                            duration: 3000,
                        });
                        return combineLatest([of(false)]);
                    }
                }),
                // on ne va pas plus loin si la boite de dialogue a été annulé
                filter(([result]) => result),
                switchMap(([result, intervention, diagnostic]) => {
                    // Si c'est le 1er diagnostic qui passe à EN_ATTENTE ou FINI,
                    // on synchronise le bien de référence de chaque bien de l'intervention en cours (principal et annexes)
                    if (
                        !this.hasDiagEnAttente(intervention) &&
                        !this.hasDiagFini(intervention) &&
                        (result.etatDiagnosticSelection === EtatDiagnostic.EN_ATTENTE || result.etatDiagnosticSelection === EtatDiagnostic.FINI)
                    ) {
                        // todo: Vérifier que la synchro des biens si dessous ne sert vraiement à rien.
                        //       En tout cas elle provoque une erreur à la finalisation si nous sommes en mode hors-ligne.
                        //       vue que je n'en vois pas l'interet je commente purement et simple cette syncho.
                        /*
                        intervention.relationInterventionBiens.forEach((relation) => {
                            this.bienApiService.synchroniserBien(relation.bien.id).subscribe();
                        });
                        */
                    }
                    return this.cnSpinnerService.withSpinner(
                        this.exportSvgService.saveScreenshots(intervention, diagnostic).pipe(
                            tap(
                                () =>
                                    (this.interReportData = this.reportService.getInterventionReportData(
                                        this.prestation,
                                        intervention,
                                        diagnostic,
                                        this.userInformation
                                    ))
                            ),
                            map(() => this.reportService.saveReportData(this.interReportData, result, intervention, diagnostic, this.typeReport))
                        )
                    );
                }),
                switchMap((s) =>
                    this.cnSpinnerService.withSpinner(
                        this.interventionService
                            .updateIntervention(s.intervention)
                            .pipe(
                                switchMap((i) =>
                                    this.diagnosticService.upsert(i, s.diagnostic).pipe(switchMap((diag) => combineLatest([of(i), of(diag)])))
                                )
                            )
                    )
                ),
                switchMap(([intervention, currentDiag]) => {
                    const diagObs$: Observable<Diagnostic>[] = intervention.prestationsDiagnostics
                        .filter((presta) => presta.idDiagnostic)
                        .map((presta) => {
                            return this.diagnosticService.findOne(presta.idDiagnostic).pipe(
                                tap((diagnostic) => {
                                    presta.etatDiagnostic = diagnostic.etat;
                                })
                            );
                        });

                    return combineLatest(diagObs$).pipe(switchMap(() => combineLatest([of(intervention), of(currentDiag)])));
                })
            )
            .subscribe(([intervention, currentDiag]) => {
                if (currentDiag) {
                    // MAJ de l'état du diagnostic dans l'intervention
                    intervention.prestationsDiagnostics.map((presta) => {
                        if (presta.idDiagnostic === currentDiag.id) {
                            presta.etatDiagnostic = currentDiag.etat;
                        }
                    });

                    // S'il n'y a plus aucun diag en cours et qu'il y a au moins 1 diag en attente,
                    // on peut passer l'intervention à EN_ATTENTE
                    // Sinon l'intervention peut passer à TERMINEE
                    if (!this.hasDiagEnCours(intervention)) {
                        if (this.hasDiagEnAttente(intervention)) {
                            intervention.etat = EtatIntervention.EN_ATTENTE;
                            this.interventionService.updateIntervention(intervention).subscribe();
                        } else {
                            this.interventionService.closeIntervention(intervention).subscribe(() => {
                                this.diagnosticService.reloadCurrentDiagnostic();
                            });
                        }
                    }
                    this.notificationService.success(`Le diagnostic ${currentDiag.typePrestation.replace(/_/g, ' ')} a bien été validé`);

                    this.interventionService.reloadCurrentIntervention(); // indispensable pour mettre à jour l'état de l'intervention

                    if (!this.hasDiagEnCours(intervention)) {
                        this.router.navigate([URL_TABLEAU_DE_BORD]);
                    }
                }
            });
    }

    /**
     * Sauvegarde dans le diagnostic les datas du bon de commande
     */
    saveBonCommandeData() {
        const diagnosticEtat = this.diagnostic.etatProgressions;
        // On vérifie s'il y a au moins un onglet invalide autre que celui de compte-rendu correspondant au rapport final
        const invalids = diagnosticEtat.concat(this.intervention.etatProgressions).filter((it) => {
            return ![BON_COMMANDE_ANALYSE, RAPPORT].includes(it.code) && ['INVALID', 'UNVERIFIED'].includes(it.etat);
        });

        const onlyOngletDocsIsInvalid = invalids.length === 1 && [DOCS, DOCS_DIAG].includes(invalids[0].code);
        if (invalids.length === 0 || onlyOngletDocsIsInvalid) {
            // Si la signature existe
            if (this.userInformation && this.userInformation.fichierSignatureId) {
                this.diagnostic.listeBonCommande[0].signed = true;
                this.interventionService
                    .updateIntervention(this.intervention)
                    .pipe(
                        switchMap(() => {
                            return this.checkValidityTabService.checkValidityBonCommande(this.diagnostic);
                        }),
                        takeUntil(this.ngUnsubscribe)
                    )
                    .subscribe((checkValidityState) => {
                        const code = this.route.snapshot.data['code'];
                        this.etatProgressionService.updateDiagnostic(code, checkValidityState, this.diagnosticService.getCurrentDiagnosticValue());
                        this.notificationService.success({
                            message: `Le bon de commande a bien été généré`,
                            showCloseButton: false,
                            duration: 3000,
                        });
                    });
            } else {
                this.notificationService.error({
                    message: `Vous devez saisir une signature avant de signer le bon de commande`,
                    showCloseButton: false,
                    duration: 3000,
                });
            }
        } else {
            this.notificationService.error({
                message: `Tous les onglets de l'intervention et du diagnostic n'ont pas été remplis`,
                showCloseButton: false,
                duration: 3000,
            });
        }
    }

    /**
     * Génération de l'estimatif uniquement POLLUANT ETUDE SITUATION
     * @private
     */
    private generateEstimatif(diagnostic) {
        if (diagnostic.typePrestation !== TypePrestationEnum.POLLUANT_ETUDE_SITUATION) {
            return;
        }
        const reportData = JSON.parse(JSON.stringify(diagnostic.reportDatas[0]));
        reportData.typeReport = 'AUTRES';
        const prestDiag = this.intervention.prestationsDiagnostics.find((prest) => prest.idDiagnostic === this.diagnostic.id);
        const prestation = prestDiag?.prestation;
        const interReportData = this.reportService.getInterventionReportData(prestation, this.intervention, this.diagnostic, this.userInformation);
        reportData.reportData = JSON.stringify(interReportData);
        reportData.refRapport = 'TABLE_' + diagnostic.reportDatas[0].refRapport;
        diagnostic.reportDatas.push(reportData);
    }

    /**
     * Si l'utiisateur est en ligne, on le renvoie sur la page de création de la signature
     * Sinon, on lui affiche une notification pour lui demander de passer en ligne
     */
    onClickBtnAddSignature() {
        this.router.navigate([URL_MON_COMPTE_SIGNATURE]);
    }

    private hasDiagFini(intervention: Intervention): boolean {
        return intervention.prestationsDiagnostics.find((prestation) => prestation.etatDiagnostic === EtatDiagnostic.FINI) != undefined;
    }

    private hasDiagEnAttente(intervention: Intervention): boolean {
        return intervention.prestationsDiagnostics.find((prestation) => prestation.etatDiagnostic === EtatDiagnostic.EN_ATTENTE) != undefined;
    }

    private hasDiagEnCours(intervention: Intervention): boolean {
        return (
            intervention.prestationsDiagnostics.find(
                (prestation) =>
                    !prestation.idDiagnostic ||
                    prestation.etatDiagnostic === EtatDiagnostic.NON_DEMARRE ||
                    prestation.etatDiagnostic === EtatDiagnostic.EN_COURS
            ) != undefined
        );
    }
}

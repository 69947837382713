import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import {
    CategorieOuvrage,
    Couleur,
    OuvrageAControler,
    Revetement,
    Substrat,
    TypeCaracteristiqueCouleur,
} from '../../../../../model/categorie-ouvrage.model';
import { Bien, Volume } from '../../../../../model/bien.model';
import { EtatWorkflow } from '../../../../../model/etat-workflow.model';
import { ConfirmDialogComponent } from '../../../../../lib/confirmation/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DescriptionBienService } from '../../../../../services/description-bien.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DescriptionBienModalOuvragesService } from '../description-bien-modal-ouvrages.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-description-bien-modal-ouvrages-parties',
    templateUrl: './description-bien-modal-ouvrages-parties.component.html',
    styleUrls: ['./description-bien-modal-ouvrages-parties.component.scss'],
})
export class DescriptionBienModalOuvragesPartiesComponent extends BaseComponent implements OnChanges {
    @Input()
    readonlyMode: boolean = false;

    @Input()
    bien: Bien;

    @Input()
    currentVolume: Volume;

    @Input()
    codeCategorieOuvrageSelected: string;

    @Input()
    mapCategoriesOuvrages: Map<string, CategorieOuvrage>;

    @Output()
    ajouterOuvrageOfCategorie: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    refreshCategoriesOuvragesParents: EventEmitter<any> = new EventEmitter<any>();

    ouvragesToDisplay: OuvrageAControler[];
    ouvragesSelectionnes: string[] = [];

    listCategoriesOuvragesEnfantsToDisplay: CategorieOuvrage[];

    afficherChoixParties: boolean = false;
    editOuvrage: boolean = false;

    selectedOuvrageEnfant: OuvrageAControler;
    selectedCategoriesOuvragesEnfants: CategorieOuvrage[] = [];

    selectedPartieOuvrage: OuvrageAControler;

    searchCategoriesOuvragesSousParties: string;

    listNomsOuvrages: string[];

    listRevetements: Revetement[];
    listSubstrats: Substrat[];
    mapCouleurs: Map<string, Couleur> = new Map<string, Couleur>();
    listCaracteristiquesCouleurs: any[];

    public selectedOuvrageToCopyContext = this.descriptionBienModalOuvragesService.selectedOuvrageToCopyContext;

    constructor(
        private readonly dialog: MatDialog,
        private readonly descriptionBienService: DescriptionBienService,
        private readonly descriptionBienModalOuvragesService: DescriptionBienModalOuvragesService
    ) {
        super();
    }

    ngOnInit() {
        this.initData();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initData();
    }

    private initData() {
        this.refreshOuvragesToDisplay();
        this.selectedOuvrageEnfant = null;
        if (this.ouvragesToDisplay.length > 0) {
            this.selectedOuvrageEnfant = this.currentVolume.ouvragesAControler.find((it) => it.id === this.ouvragesToDisplay[0].id);
        }
        this.selectedCategoriesOuvragesEnfants = [];
        this.selectedPartieOuvrage = this.selectedOuvrageEnfant?.partiesOuvrages[0];
        this.editOuvrage = false;
        this.afficherChoixParties = false;
        this.refreshListsRevetements();
        this.filterListCategoriesOuvragesEnfantsToDisplay();
    }

    handleSelectionnerCategorieSousPartie(categorieOuvrageEnfant: CategorieOuvrage) {
        const index = this.selectedCategoriesOuvragesEnfants.findIndex((it) => it === categorieOuvrageEnfant);
        if (index != -1) {
            this.selectedCategoriesOuvragesEnfants.splice(index, 1);
        } else {
            this.selectedCategoriesOuvragesEnfants.push(categorieOuvrageEnfant);
        }
    }

    handleSelectOuvrage(ouvrage: OuvrageAControler) {
        const ouvrageSelected = this.currentVolume.ouvragesAControler.find((it) => it.id === ouvrage.id);
        if (this.selectedOuvrageEnfant === ouvrageSelected) {
            this.selectedOuvrageEnfant = null;
            this.selectedPartieOuvrage = null;
        } else {
            this.ouvragesSelectionnes = [];
            this.selectedOuvrageEnfant = ouvrageSelected;
            this.selectedPartieOuvrage = this.selectedOuvrageEnfant.partiesOuvrages[0];

            this.refreshListsRevetements();
            this.filterListCategoriesOuvragesEnfantsToDisplay();
        }
        this.selectedCategoriesOuvragesEnfants = [];
        this.editOuvrage = false;
        this.afficherChoixParties = false;
    }

    handleSelectionnerSousPartieOuvrage(partieOuvrage: OuvrageAControler) {
        const partieOuvrageSelected = this.selectedOuvrageEnfant.partiesOuvrages.find((it) => it.id === partieOuvrage.id);
        if (this.selectedPartieOuvrage === partieOuvrageSelected) {
            this.selectedPartieOuvrage = null;
        } else {
            this.selectedPartieOuvrage = partieOuvrageSelected;
            this.refreshListsRevetements();
        }
    }

    handleChoisirSousParties() {
        this.selectedOuvrageEnfant.partiesOuvrages = this.selectedOuvrageEnfant.partiesOuvrages ?? [];
        this.selectedCategoriesOuvragesEnfants.forEach((it, index) => {
            const newPartieOuvrage = new OuvrageAControler();
            newPartieOuvrage.nom = it.nomOuvrage;
            newPartieOuvrage.codeCategorieOuvrage = it.code;

            newPartieOuvrage.codeSubstrat = this.selectedOuvrageEnfant.codeSubstrat;
            newPartieOuvrage.nomSubstrat = this.selectedOuvrageEnfant.nomSubstrat;
            newPartieOuvrage.codeRevetement = this.selectedOuvrageEnfant.codeRevetement;
            newPartieOuvrage.nomRevetement = this.selectedOuvrageEnfant.nomRevetement;
            newPartieOuvrage.valeurCouleur = this.selectedOuvrageEnfant.valeurCouleur;
            newPartieOuvrage.nomCouleur = this.selectedOuvrageEnfant.nomCouleur;
            newPartieOuvrage.valeurCaracteristiqueCouleur = this.selectedOuvrageEnfant.valeurCaracteristiqueCouleur;

            this.selectedOuvrageEnfant.partiesOuvrages.push(newPartieOuvrage);
            if (index === 0) {
                this.selectedPartieOuvrage = newPartieOuvrage;
            }
        });
        this.afficherChoixParties = false;
        this.selectedCategoriesOuvragesEnfants = [];
        this.refreshListsRevetements();
    }

    handleDupliquerOuvrage($event, ouvrage: OuvrageAControler) {
        $event.stopPropagation();
        this.descriptionBienService.dupliquerOuvrageAControler(ouvrage, { volume: this.currentVolume, bien: this.bien });
        this.refreshOuvragesToDisplay();
        this.refreshListNoms();
        this.refreshCategoriesOuvragesParents.emit();
    }

    handleSupprimerOuvrage($event, ouvrage: OuvrageAControler) {
        $event.stopPropagation();
        const message = "Voulez-vous vraiment supprimer l'ouvrage " + ouvrage.nom;
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    const indexCurrentVolume = this.currentVolume.ouvragesAControler.findIndex((it) => it.id === ouvrage.id);
                    this.currentVolume.ouvragesAControler.splice(indexCurrentVolume, 1);
                    this.refreshOuvragesToDisplay();
                    if (this.selectedOuvrageEnfant === ouvrage) {
                        this.selectedOuvrageEnfant = this.currentVolume.ouvragesAControler.find((it) => it.id === this.ouvragesToDisplay[0]?.id);
                        this.selectedPartieOuvrage = this.selectedOuvrageEnfant?.partiesOuvrages[0];
                    }
                    this.refreshListNoms();
                    this.refreshCategoriesOuvragesParents.emit();
                }
            });
    }

    handleAjouterSousParties() {
        this.afficherChoixParties = true;
        this.selectedPartieOuvrage = null;
        this.filterListCategoriesOuvragesEnfantsToDisplay();
    }

    handleAjouterOuvrage() {
        this.ajouterOuvrageOfCategorie.emit(this.codeCategorieOuvrageSelected);
    }

    onKeyupSearchCategoriesOuvragesSousParties($event) {
        this.filterListCategoriesOuvragesEnfantsToDisplay();
    }

    clearSearchCategoriesOuvragesSousParties() {
        this.searchCategoriesOuvragesSousParties = null;
        this.filterListCategoriesOuvragesEnfantsToDisplay();
    }

    handleSupprimerPartieOuvrage($event, partieOuvrage) {
        $event.stopPropagation();
        const message = "Voulez-vous vraiment supprimer la partie d'ouvrage " + partieOuvrage.nom;
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    const index = this.selectedOuvrageEnfant.partiesOuvrages.findIndex((it) => it.id === partieOuvrage.id);
                    this.selectedOuvrageEnfant.partiesOuvrages.splice(index, 1);
                }
            });
    }

    refreshOuvragesToDisplay() {
        this.ouvragesToDisplay = this.currentVolume.ouvragesAControler
            .filter((it) => this.mapCategoriesOuvrages.get(it.codeCategorieOuvrage).lienCodeParent === this.codeCategorieOuvrageSelected)
            .sort((o1, o2) => (o1.codeCategorieOuvrage > o2.codeCategorieOuvrage ? 1 : -1))
            .sort((o1, o2) => (o1.nom > o2.nom ? 1 : -1));
        this.ouvragesSelectionnes = this.ouvragesToDisplay.filter((o) => this.ouvragesSelectionnes.includes(o.id)).map((o) => o.id);
    }

    private refreshListsRevetements() {
        if (this.selectedPartieOuvrage) {
            const currentCategorie = this.mapCategoriesOuvrages.get(this.selectedPartieOuvrage.codeCategorieOuvrage);
            this.listRevetements = currentCategorie.revetements;
            this.listSubstrats = currentCategorie.substrats;
            currentCategorie.couleurs.forEach((it) => {
                this.mapCouleurs.set(it.valeur, it);
            });
            this.listCaracteristiquesCouleurs = Object.keys(TypeCaracteristiqueCouleur).map((it) => {
                return {
                    nom: TypeCaracteristiqueCouleur[it],
                    valeur: it,
                };
            });
        }
    }

    private filterListCategoriesOuvragesEnfantsToDisplay() {
        this.listCategoriesOuvragesEnfantsToDisplay = Array.from(this.mapCategoriesOuvrages.values())
            .filter((it: CategorieOuvrage) => it.lienCodeParent && it.lienCodeParent === this.selectedOuvrageEnfant?.codeCategorieOuvrage)
            .filter((it) => {
                return (
                    it.etatCategorieOuvrage === EtatWorkflow.ACTIF &&
                    (!this.searchCategoriesOuvragesSousParties ||
                        it.nom.toLowerCase().includes(this.searchCategoriesOuvragesSousParties.toLowerCase()))
                );
            });
    }

    private refreshListNoms() {
        this.listNomsOuvrages = this.ouvragesToDisplay
            .filter((it) => it.id != this.selectedOuvrageEnfant?.id)
            .map((it) => it.nom.toLocaleUpperCase().trim());
    }

    toggleOuvrage(ouvrage: OuvrageAControler, $event: MatCheckboxChange) {
        if ($event.checked) {
            this.ouvragesSelectionnes = Array.from(new Set(this.ouvragesSelectionnes.concat(ouvrage.id)));
            this.selectedOuvrageEnfant = null;
        } else {
            this.ouvragesSelectionnes = this.ouvragesSelectionnes.filter((it) => it !== ouvrage.id);
        }
    }

    toggleAllOuvrage($event: MatCheckboxChange) {
        if ($event.checked) {
            this.ouvragesSelectionnes = this.ouvragesToDisplay.map((it) => it.id);
            this.selectedOuvrageEnfant = null;
        } else {
            this.ouvragesSelectionnes = [];
        }
    }

    copyOuvrage(ouvrageSelectionne: string, partieOuvrageId: string = null, onlyRevetement = false) {
        const ouvrage = this.currentVolume.ouvragesAControler.find((it) => it.id === ouvrageSelectionne);
        const volume = this.currentVolume;
        const niveau = this.bien.description.find((it) => it.volumes.some((v) => v.id === volume.id));
        const bien = this.bien;
        const selectedParties = ouvrage.partiesOuvrages.filter((it) => !partieOuvrageId || it.id === partieOuvrageId).map((it) => it.id);
        const ouvrageToCopy = {
            ouvrage,
            volume,
            niveau,
            bien,
            selectedParties,
            onlyRevetement,
        };
        if (onlyRevetement) {
            this.descriptionBienModalOuvragesService.selectPartieToCopy(ouvrageToCopy);
        } else {
            this.descriptionBienModalOuvragesService.selectOuvrageToCopy(ouvrageToCopy);
        }
    }

    protected readonly console = console;
    identifyOuvrage = (index: number, ouvrage: OuvrageAControler) => ouvrage.id;

    copierProprietesOuvrages(ouvrageTarget?: OuvrageAControler) {
        const ouvragesTarget: OuvrageAControler[] = [];
        if (ouvrageTarget) {
            ouvragesTarget.push({ partiesOuvrages: [ouvrageTarget] } as any as OuvrageAControler);
        } else if (this.selectedOuvrageEnfant) {
            ouvragesTarget.push(this.selectedOuvrageEnfant);
        } else {
            ouvragesTarget.push(...this.ouvragesToDisplay.filter((it) => this.ouvragesSelectionnes.includes(it.id)));
        }
        this.descriptionBienModalOuvragesService.copierProprietesOuvrages(ouvragesTarget).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe();
    }

    deplacerPartieOuvrage($event: MouseEvent, currentIndex, newIndex: number) {
        $event.stopPropagation();
        const ouvrageAControlers = this.selectedOuvrageEnfant.partiesOuvrages.splice(currentIndex, 1);
        this.selectedOuvrageEnfant.partiesOuvrages.splice(newIndex, 0, ...ouvrageAControlers);
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';
import { combineLatest, of } from 'rxjs';
import { map, pairwise, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EtatWorkflow } from '../../../../model/etat-workflow.model';
import { ReferenceService } from '../../../../services/reference.service';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { DescriptionBienService, isANumber } from '../../../../services/description-bien.service';
import { extraireNomVolume } from '../../../../utils/bien.utils';
import { PARAM_VOLUME_LOT } from '../../../../shared/constants/cndiag.constants';

class PieceModalData {
    constructor(public bien: Bien, public niveau: Niveau, public volume: Volume, public typeVolume: TypeVolume, public listNomsVolumes: string[]) {}
}

@Component({
    selector: 'app-description-bien-modal-piece',
    templateUrl: './description-bien-modal-piece.component.html',
    styleUrls: ['./description-bien-modal-piece.component.scss'],
})
export class DescriptionBienModalPieceComponent extends BaseComponent implements OnInit {
    form: FormGroup;

    listTypeVolumes: TypeVolume[];
    initialTypeVolume: TypeVolume = null;
    initialName = '';
    numerotationDisabled = true;

    constructor(
        private dialogRef: MatDialogRef<DescriptionBienModalPieceComponent>,
        private formBuilder: FormBuilder,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly descriptionBienService: DescriptionBienService,
        @Inject(MAT_DIALOG_DATA) public data: PieceModalData
    ) {
        super();
    }

    ngOnInit() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.referenceService.findAllTypesVolumes()]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([pieces]) => {
                        return combineLatest([of(pieces)]);
                    })
                )
            )
            .subscribe(([pieces]) => {
                this.listTypeVolumes = pieces.filter((piece) => piece.etatVolume === EtatWorkflow.ACTIF);

                const typeVolume = this.listTypeVolumes.find((it) => it.id === this.data.volume?.usageId);
                const numLot = this.data.volume.valeursParametres[PARAM_VOLUME_LOT] ?? this.data.bien.numeroLot;
                this.form = this.formBuilder.group({
                    valeurNumerotation:
                        (isANumber(this.data.volume.numerotationDebut) ? this.data.volume.numerotationDebut : 1) +
                        (isANumber(this.data.volume.numerotation) ? this.data.volume.numerotation : 0),
                    numerotationDebut: isANumber(this.data.volume.numerotationDebut) ? this.data.volume.numerotationDebut : 1,
                    prefixeNumerotation: this.data.volume.prefixeNumerotation,
                    nomBase: this.data.volume.nomBase,
                    selectedTypeVolume: typeVolume?.id,
                    numLot: numLot,
                });
                this.initialTypeVolume = typeVolume;
                this.initialName = this.data.volume.nom;
                this.form
                    .get('selectedTypeVolume')
                    .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => this.updatePieceFromTypeVolume());
                this.form
                    .get('numerotationDebut')
                    .valueChanges.pipe(
                        pairwise(),
                        map(([oldValue, newValue]) => {
                            if (oldValue && newValue && oldValue !== newValue) {
                                if (oldValue > newValue) {
                                    this.form.get('valeurNumerotation').setValue(this.form.get('valeurNumerotation').value - 1);
                                } else {
                                    this.form.get('valeurNumerotation').setValue(this.form.get('valeurNumerotation').value + 1);
                                }
                            }
                        }),
                        takeUntil(this.ngUnsubscribe)
                    )
                    .subscribe();
                this.form
                    .get('nomBase')
                    .valueChanges.pipe(
                        takeUntil(this.ngUnsubscribe),
                        tap((nomBase) => {
                            const fields = ['valeurNumerotation', 'numerotationDebut', 'prefixeNumerotation'];
                            if (nomBase?.length) {
                                fields.forEach((field) => this.form.get(field).enable());
                                this.numerotationDisabled = false;
                            } else {
                                fields.forEach((field) => this.form.get(field).disable());
                                this.numerotationDisabled = true;
                            }
                        })
                    )
                    .subscribe();
                const fields = ['valeurNumerotation', 'numerotationDebut', 'prefixeNumerotation'];
                if (this.data.volume.nomBase?.length) {
                    fields.forEach((field) => this.form.get(field).enable());
                    this.numerotationDisabled = false;
                } else {
                    fields.forEach((field) => this.form.get(field).disable());
                    this.numerotationDisabled = true;
                }
            });
    }

    confirm() {
        const typeVolume = this.listTypeVolumes.find((it) => it.id === this.form.get('selectedTypeVolume').value);
        const volumeResult = this.data.volume ? this.data.volume : new Volume();
        volumeResult.usageId = typeVolume?.id;
        if (typeVolume?.id !== this.initialTypeVolume?.id) {
            for (var key in typeVolume?.valeursDefautParametres) {
                delete volumeResult.valeursParametres[key];
            }
            volumeResult.valeursParametres = {
                ...volumeResult.valeursParametres,
                ...(typeVolume?.valeursDefautParametres ?? {}),
            };
        }

        volumeResult.numerotationDebut = this.form.get('numerotationDebut').value ?? 1;
        volumeResult.numerotation = (this.form.get('valeurNumerotation').value ?? 1) - volumeResult.numerotationDebut;
        volumeResult.prefixeNumerotation = this.form.get('prefixeNumerotation').value ?? '';
        volumeResult.nomBase = this.form.get('nomBase').value ?? '';
        const numLot = this.form.get('numLot').value;
        volumeResult.valeursParametres[PARAM_VOLUME_LOT] = numLot !== this.data.bien.numeroLot ? numLot : undefined;

        this.data.niveau.volumes.forEach((volume) => {
            if (volume.nomBase === volumeResult.nomBase) {
                volume.numerotationDebut = volumeResult.numerotationDebut;
                volume.prefixeNumerotation = volumeResult.prefixeNumerotation;
            }
            volume.nom = this.descriptionBienService.generateVolumeName(volume, this.data.niveau);
        });

        volumeResult.nom = this.descriptionBienService.generateVolumeName(volumeResult, this.data.niveau);

        this.dialogRef.close(volumeResult);
    }

    close() {
        this.dialogRef.close(false);
    }

    private updatePieceFromTypeVolume() {
        const newVolumeType = this.form.get('selectedTypeVolume').value;
        const typeVolume = this.listTypeVolumes.find((it) => it.id === newVolumeType);
        this.form.get('nomBase').setValue(typeVolume.nom);
    }
}

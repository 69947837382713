import { BaseComponent, ConfirmationService, MongoUtils } from 'src/app/commons-lib';
import { Component, OnInit } from '@angular/core';
import { Intervention } from '../../../model/intervention.model';
import { Espace } from '../../../model/espace.model';
import { Bien } from '../../../model/bien.model';
import { DrawingPicture } from '@acenv/cnmap-angular-editor-lib';
import { Diagnostic } from '../../../model/diagnostic.model';
import { MatDialog } from '@angular/material/dialog';
import { CnSpinnerService } from '../../shared/cn-spinner/service/cn-spinner.service';
import { ActivatedRoute } from '@angular/router';
import { EtatProgressionService } from '../../../services/etat-progression.service';
import { InterventionService } from '../../../services/intervention.service';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { HapService } from '../../diagnostics/hap/services/hap.service';
import { ExportSvgService } from '../../shared/map/export-svg-dialog/shared/services/export-svg.service';
import { cn_background_map, cn_building } from '@acenv/cnmap-editor';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ExportSvgDialogComponent } from '../../shared/map/export-svg-dialog/export-svg-dialog.component';
import { DialogUtils } from '../../../utils/dialog.utils';
import { ScreenshotSvg } from '../../../model/screenshot-svg.model';
import { Hap } from '../../diagnostics/hap/model/hap.model';
import { exportSvgTypes, SvgConfigType } from '../../shared/map/export-svg-dialog/shared/conf/export-svg-types.conf';
import * as _ from 'lodash';
import { combineLatestOrEmpty } from '../../../utils/rxjs.utils';

function buildingOrDefault(jsonPlan: string) {
    if (jsonPlan) {
        return cn_building.unserialize(JSON.parse(jsonPlan));
    } else {
        return cn_building.generate_new_building();
    }
}

@Component({
    selector: 'app-gestion-plan',
    templateUrl: './gestion-plan.component.html',
    styleUrls: ['./gestion-plan.component.scss'],
})
export class GestionPlanComponent extends BaseComponent implements OnInit {
    hasPlan = false;
    readonlyMode = false;
    optionPlan: boolean;
    hasEspace: boolean;

    private currentIntervention: Intervention;
    private espaces: Espace[];
    private biensWithPlan: Bien[];
    private backgroundMaps: DrawingPicture[] = [];

    currentDiagnostic: Diagnostic;

    exportSvgTypes = exportSvgTypes;
    exportSvgTypesFiltered: SvgConfigType[] = [];
    screenshotsWithJson: {
        screenshotSvg: ScreenshotSvg;
        svgPlan: string;
    }[];

    constructor(
        private readonly matDialog: MatDialog,
        private readonly confirmationService: ConfirmationService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionService: InterventionService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly route: ActivatedRoute,
        private readonly diagnosticService: DiagnosticService,
        private readonly hapService: HapService,
        private readonly exportSvgService: ExportSvgService
    ) {
        super();
        // Définition de la méthode pour récupérer le contenu du backgroundMap
        (cn_background_map as any).image_id_to_url = (fileId) => {
            return (this.backgroundMaps.find((bgmu) => bgmu.fileId == fileId) || ({} as DrawingPicture)).imageUrl;
        };
    }

    ngOnInit(): void {
        // Récupération de l'intervention courante et de la liste des biens possédant des plans + screenshots
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService
                        .getCurrentIntervention()
                        .pipe(
                            switchMap((intervention) =>
                                combineLatestOrEmpty([...this.hapService.requestAllBackgroundImage(intervention)]).pipe(map(() => intervention))
                            )
                        ),
                    this.diagnosticService.getCurrentDiagnostic(),
                ]).pipe(
                    switchMap(([intervention, diagnostic]) => {
                        this.currentIntervention = intervention;
                        // Sauvegarde de l'ensemble des backgroundMap des biens
                        intervention.relationInterventionBiens.forEach((relationInterventionBienTemp) => {
                            relationInterventionBienTemp.bien.backgroundMaps.forEach((backgroundMapTemp) => {
                                if (backgroundMapTemp.imageUrl) {
                                    this.backgroundMaps.push(backgroundMapTemp);
                                }
                            });
                        });
                        const code = this.route.snapshot.data['code'];
                        this.etatProgressionService.updateDiagnostic(code, 'VOID', diagnostic);
                        // Présence de plans dans les biens de l'intervention ?
                        this.biensWithPlan = this.currentIntervention.relationInterventionBiens
                            .filter((relation) => !!relation.bien.jsonPlan)
                            .flatMap((relation) => relation.bien);
                        this.currentDiagnostic = diagnostic;
                        this.espaces = (this.currentDiagnostic?.contenuDiagnostic as Hap)?.espaces?.valeur || [];
                        this.hasEspace = this.espaces.length > 0;
                        this.hasPlan = !!this.biensWithPlan.length || this.hasEspace;
                        this.readonlyMode = this.diagnosticService.isReadOnlyMode(intervention, diagnostic);
                        this.optionPlan = this.diagnosticService.getCurrentOptionPlan(intervention, diagnostic);

                        if (this.optionPlan) {
                            this.generateAutoScreenshots(intervention);
                        }

                        return this.exportSvgService.refreshScreenshots(this.currentIntervention, this.currentDiagnostic);
                    }),
                    tap((r) => (this.screenshotsWithJson = r)),
                    takeUntil(this.ngUnsubscribe)
                )
            )
            .subscribe();
    }

    /**
     * Création d'un nouveau plan de repérage.
     */
    onClickAddNewScreenshot() {
        return this.matDialog
            .open<ExportSvgDialogComponent, any, { screenshotSvg: ScreenshotSvg; svgPlan: string }>(ExportSvgDialogComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    biens: this.biensWithPlan,
                    espaces: this.espaces,
                    typePrestation: this.currentDiagnostic.typePrestation,
                },
            })
            .afterClosed()
            .pipe(
                filter((result) => !!result?.screenshotSvg),
                switchMap((result) => this.cnSpinnerService.withSpinner(this.addScreenshot(result.screenshotSvg))),
                switchMap(() => this.exportSvgService.refreshScreenshots(this.currentIntervention, this.currentDiagnostic)),
                tap((screenshotsWithJson) => (this.screenshotsWithJson = screenshotsWithJson)),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe();
    }

    /**
     * Suppression d'un plan de repérage
     * @param screenshot
     */
    onClickDeleteScreenshot(screenshot: ScreenshotSvg) {
        const nameType = JSON.parse(screenshot.configurationSvg).name;
        const confirmMessage =
            'Êtes-vous sûr de vouloir supprimer ce plan de repérage ?' +
            (screenshot.autoGenerated
                ? '<br/> Attention, la suppression de ce plan va entraîner l\'arrêt de la génération des plans de type "' + nameType + '"'
                : '');
        this.confirmationService.confirmWarn(confirmMessage, () => {
            this.cnSpinnerService
                .withSpinner(this.deleteScreenshot(screenshot), 'Suppression en cours...')
                .pipe(
                    switchMap(() => this.exportSvgService.refreshScreenshots(this.currentIntervention, this.currentDiagnostic)),
                    tap((screenshotsWithJson) => (this.screenshotsWithJson = screenshotsWithJson)),
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe();
        });
    }

    onClickEditScreenshot(screenshot: ScreenshotSvg) {
        return this.matDialog
            .open(ExportSvgDialogComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    biens: this.biensWithPlan,
                    espaces: this.espaces,
                    typePrestation: this.currentDiagnostic.typePrestation,
                    svgConfiguration: JSON.parse(screenshot.configurationSvg),
                    screenshotId: screenshot.id,
                    cameraPosition: screenshot.cameraPosition,
                },
            })
            .afterClosed()
            .pipe(
                filter((result) => result && result !== false && result.screenshotSvg),
                switchMap((result) => this.cnSpinnerService.withSpinner(this.editScreenshot(result.screenshotSvg))),
                switchMap(() => this.exportSvgService.refreshScreenshots(this.currentIntervention, this.currentDiagnostic)),
                tap((screenshotsWithJson) => (this.screenshotsWithJson = screenshotsWithJson)),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe();
    }

    /**
     * Ajout d'un plan de repérage au diagnostic
     * @param screenshotSvg
     * @private
     */
    private addScreenshot(screenshotSvg: ScreenshotSvg) {
        screenshotSvg.id = MongoUtils.generateObjectId();
        this.currentDiagnostic.screenshotsPlan.push(screenshotSvg);

        return this.diagnosticService.upsert(this.currentIntervention, this.currentDiagnostic);
    }

    /**
     * Suppression du screenshot + maj du diagnostic
     * @param screenshotId
     * @private
     */
    private deleteScreenshot(screenshot: ScreenshotSvg): Observable<any> {
        if (this.currentDiagnostic.screenshotsPlan.length) {
            // Suppression du screenshot dans la ref de bien :
            this.currentDiagnostic.screenshotsPlan = this.currentDiagnostic.screenshotsPlan.filter((it) => it.id != screenshot.id);
            const presetScreenshots = this.currentIntervention.prestationsDiagnostics.find((it) => it.idDiagnostic === this.currentDiagnostic.id)
                .prestation.presetsExportScreenshot;
            const config = JSON.parse(screenshot.configurationSvg);
            if (screenshot.autoGenerated) {
                if (presetScreenshots.includes(config.id)) {
                    presetScreenshots.splice(config.id, 1);
                }
            }
            return forkJoin([
                this.diagnosticService.upsert(this.currentIntervention, this.currentDiagnostic),
                this.interventionService.updateIntervention(this.currentIntervention),
            ]).pipe(
                map(([diagnostic, intervention]) => {
                    return diagnostic;
                })
            );
        } else {
            return of();
        }
    }

    private editScreenshot(screenshotSvg: ScreenshotSvg) {
        const index = this.currentDiagnostic.screenshotsPlan.indexOf(this.currentDiagnostic.screenshotsPlan.find((it) => it.id === screenshotSvg.id));
        this.currentDiagnostic.screenshotsPlan[index] = screenshotSvg;

        return this.diagnosticService.upsert(this.currentIntervention, this.currentDiagnostic);
    }

    private generateAutoScreenshots(intervention: Intervention) {
        const prestDiag = intervention.prestationsDiagnostics.find((prest) => prest.idDiagnostic === this.currentDiagnostic.id);
        const confsIdsSvgExport = prestDiag.prestation.presetsExportScreenshot;
        if (confsIdsSvgExport.length) {
            this.exportSvgTypesFiltered = this.exportSvgTypes.filter((conf) => confsIdsSvgExport.includes(conf.id));
        }

        this.exportSvgTypesFiltered
            .filter((typeConf) => !typeConf.espace?.controls.espace.displayed)
            .forEach((typeConf) => {
                this.currentDiagnostic.pointsDeControleBiens.forEach((pcB) => {
                    pcB.pointsDeControleNiveaux.forEach((pcN) => {
                        const bien = this.currentIntervention.relationInterventionBiens.find((it) => it.bien.id === pcB.idBien).bien;

                        let conf = _.cloneDeep(typeConf);

                        conf.bien.controls.bien.value = { id: bien.id };
                        conf.bien.controls.bien.nom = bien.nom;
                        const niveau = bien.description.find((it) => it.id === pcN.idNiveau);
                        conf.bien.controls.niveau.value = { id: niveau.id, storeyId: niveau.storeyId };
                        if (pcN.storeyId) {
                            const building = cn_building.unserialize(JSON.parse(bien.jsonPlan));
                            const storey = building.find_storey(pcN.storeyId);

                            conf.main.controls.nom.value = `${building.name} - Niveau - ${storey.get_storey_name()} - ${conf.name}`;
                            conf.plan.groups.general.controls.fixedScale.value = true;
                            const defaultNameScreenshot = conf.main.controls.nom.value;

                            if (
                                !this.currentDiagnostic.screenshotsPlan.filter((screnshotPlan) => {
                                    return JSON.parse(screnshotPlan.configurationSvg).id === conf.id && screnshotPlan.storeyId === pcN.storeyId;
                                }).length
                            ) {
                                const screenshotSvg = {
                                    cameraPosition: undefined,
                                    configurationSvg: JSON.stringify(conf),
                                    id: MongoUtils.generateObjectId(),
                                    jsonPlan: '',
                                    nom: defaultNameScreenshot,
                                    storeyId: pcN.storeyId,
                                    autoGenerated: true,
                                };

                                this.currentDiagnostic.screenshotsPlan.push(screenshotSvg);
                            }
                        }
                    });
                });
            });

        this.exportSvgTypesFiltered
            .filter((typeConf) => typeConf.espace && typeConf.espace.controls.espace.displayed)
            .forEach((typeConf) => {
                (this.currentDiagnostic.contenuDiagnostic as Hap).espaces.valeur.forEach((espace) => {
                    let conf = _.cloneDeep(typeConf);
                    const bien = this.currentIntervention.relationInterventionBiens.find((it) => it.bien.id === espace.idBien).bien;

                    const storey = bien.description.find((it) => it.index === espace.indexNiveau);

                    conf.bien.controls.bien.value = { id: bien.id };
                    conf.bien.controls.bien.nom = bien.nom;
                    if (storey) {
                        conf.bien.controls.niveau.value = { id: storey.id, storeyId: storey.storeyId };
                    }
                    conf.espace.controls.espace.value = { id: espace.id };
                    conf.espace.controls.espace.nom = bien.nom;

                    const building = buildingOrDefault(bien.jsonPlan);

                    conf.main.controls.nom.value = `${building.name} - ${espace.nom} - ${conf.name}`;
                    conf.plan.groups.general.controls.fixedScale.value = true;
                    const defaultNameScreenshot = conf.main.controls.nom.value;

                    if (
                        !this.currentDiagnostic.screenshotsPlan.filter((screnshotPlan) => {
                            return (
                                JSON.parse(screnshotPlan.configurationSvg).id === conf.id &&
                                JSON.parse(screnshotPlan.configurationSvg).espace.controls.espace.value?.id === espace.id
                            );
                        }).length
                    ) {
                        const screenshotSvg = {
                            cameraPosition: undefined,
                            configurationSvg: JSON.stringify(conf),
                            id: MongoUtils.generateObjectId(),
                            jsonPlan: '',
                            nom: defaultNameScreenshot,
                            storeyId: storey?.storeyId,
                            autoGenerated: true,
                        };

                        this.currentDiagnostic.screenshotsPlan.push(screenshotSvg);
                    }
                });
            });
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent, ConfirmationService } from 'src/app/commons-lib';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { AddNiveauModalComponent } from '../add-niveau-modal/add-niveau-modal.component';
import { InterventionService } from '../../../../services/intervention.service';
import { MatDialog } from '@angular/material/dialog';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { Intervention } from '../../../../model/intervention.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { DiagnosticHandlerService } from '../../../../services/diagnostic-handler.service';
import { InterventionFileService } from '../../../../services/intervention-file.service';
import { PARAM_VOLUME_VISITE, VOLUME_VISITE } from '../../../../shared/constants/cndiag.constants';
import { DescriptionBienService } from '../../../../services/description-bien.service';
import { ConfirmDialogComponent } from '../../../../lib/confirmation/confirm-dialog.component';
import { switchMap, takeUntil } from 'rxjs/operators';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';
import { combineLatest, of } from 'rxjs';
import { ReferenceService } from '../../../../services/reference.service';
import { cn_building } from '@acenv/cnmap-editor';
import { getStoreyName } from '../../../../services/sync-bien-plan.service';

@Component({
    selector: 'app-description-bien-gestion-niveau',
    templateUrl: './description-bien-gestion-niveau.component.html',
    styleUrls: ['./description-bien-gestion-niveau.component.scss'],
})
export class DescriptionBienGestionNiveauComponent extends BaseComponent {
    @Input()
    readonlyMode: boolean = false;

    @Input()
    intervention: Intervention;

    @Input() currentNiveau: Niveau;

    @Input()
    currentBien: Bien;

    @Input()
    selectedVolume: Volume;

    @Output()
    refreshJustificationsVolumes: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    selectedVolumeChange: EventEmitter<Volume> = new EventEmitter<Volume>();

    @Output()
    currentNiveauChange: EventEmitter<Niveau> = new EventEmitter<Niveau>();

    @Output()
    sansDescriptionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    PARAM_VOLUME_VISITE = PARAM_VOLUME_VISITE;
    VOLUME_VISITE = VOLUME_VISITE;

    niveauVolumeToMerge: Niveau;
    volumeToMerge: Volume;

    niveauAJustifier: boolean = false;

    private typeVolumes: TypeVolume[];
    isPlanInBien: boolean = false;

    constructor(
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly diagnosticHandlerService: DiagnosticHandlerService,
        private readonly descriptionBienService: DescriptionBienService,
        private readonly confirmationService: ConfirmationService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionFileService: InterventionFileService,
        private readonly referenceService: ReferenceService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.descriptionBienService.getVolumeToMerge(), this.referenceService.findAllTypesVolumes()]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([volumeToMerge, typeVolumes]) => {
                        return combineLatest([of(volumeToMerge), of(typeVolumes)]);
                    })
                )
            )
            .subscribe(([volumeToMerge, typeVolumes]) => {
                this.volumeToMerge = volumeToMerge;
                if (volumeToMerge) {
                    this.niveauVolumeToMerge = this.currentBien.description.find((niveau) =>
                        niveau.volumes.some((volume) => volume.id === this.volumeToMerge.id)
                    );
                }

                this.typeVolumes = typeVolumes;
            });
        this.refreshJustificationNiveau();
        this.isPlanInBien = this.currentBien?.description?.some((objet) => {
            return objet.storeyId !== null && objet.storeyId !== undefined;
        });
    }

    ngOnChanges() {
        this.refreshJustificationNiveau();
    }

    handleEditerNiveau() {
        return this.matDialog
            .open(AddNiveauModalComponent, {
                data: {
                    bien: this.currentBien,
                    niveauToEdit: this.currentNiveau,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    const index = this.currentBien.description.indexOf(this.currentNiveau);
                    if (index !== -1) {
                        this.currentBien.description[index].nom = result.niveau.nom;
                        if (this.currentBien.jsonPlan) {
                            const building = cn_building.unserialize(JSON.parse(this.currentBien.jsonPlan));
                            const storey = building.storeys.find((it) => it.ID === this.currentNiveau.storeyId);
                            const currentName = getStoreyName(storey);
                            if (currentName !== this.currentNiveau.nom) {
                                storey.name = this.currentNiveau.nom;
                            }
                            this.currentBien.jsonPlan = JSON.stringify(building.serialize());
                        }
                    }
                    this.currentNiveau.nom = result.niveau.nom;
                }
            });
    }

    handleDupliquerNiveau() {
        this.descriptionBienService.dupliquerNiveau(this.currentBien, this.currentNiveau);
    }

    handleSupprimerNiveau() {
        // TODO : A revoir
        let msg = 'Êtes-vous sûr de vouloir supprimer ce niveau ?';
        let isAlreadyUsed = false;
        this.diagnosticService.getAllDiagnosticsForCurrentIntervention(this.intervention).subscribe((diagnostics: Diagnostic[]) => {
            if (diagnostics) {
                const isAlreadyUsed = diagnostics.some((it) => {
                    const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(it.typePrestation);
                    if (typePrestationService) {
                        return typePrestationService.isItemAlreadyFilled(it, 'niveau', this.currentNiveau.id);
                    }
                });
                if (isAlreadyUsed) {
                    msg =
                        '<div class="warn">Attention, ce niveau a déjà été renseigné dans un diagnostic.</div>Êtes-vous sûr de vouloir le supprimer ?';
                }
            }

            this.confirmationService.confirmWarn(msg, () => {
                const index = this.currentBien.description.indexOf(this.currentNiveau);
                if (index > -1) {
                    this.currentBien.description.splice(index, 1);

                    // Suppression des commentaires et des images rattachées, si présentes
                    const flattenedComs = this.currentNiveau.volumes.flatMap((v) => v.commentairesId);
                    flattenedComs.forEach((comId) => {
                        const com = this.intervention.commentaires.find((c) => c.id === comId);
                        if (com && com.imageId) {
                            // Suppression de l'image
                            // this.cnSpinnerService
                            //     .withSpinner(
                            //         this.interventionFileService.deleteInterventionFileAndFile(
                            //             this.intervention.id,
                            //             this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                            //             com.id,
                            //             TypeReferenceFichier.PHOTO_COMMENTAIRE,
                            //             com.imageId
                            //         )
                            //     )
                            //     .pipe(takeUntil(this.ngUnsubscribe))
                            //     .subscribe();
                        }
                        this.intervention.commentaires.splice(this.intervention.commentaires.indexOf(com), 1);
                    });
                    // On remet à jour les indexes de niveaux
                    this.currentBien.description.forEach((niv) => (niv.index = this.currentBien.description.indexOf(niv)));
                    // this.createRoomIndexes();
                    this.cnSpinnerService
                        .withSpinner(this.interventionService.updateIntervention(this.intervention), 'Suppression en cours...')
                        .subscribe(() => {
                            this.refreshJustificationsVolumes.emit();
                            this.currentNiveau = this.currentBien.description[0];
                            this.currentNiveauChange.emit(this.currentNiveau);
                            // this.notificationService.success('Le niveau a bien été supprimé.');
                            // this.checkValidity();
                        });
                }
            });
        });
    }

    handleDupliquerVolume($event, volume) {
        $event.stopPropagation();
        const message = 'Voulez-vous vraiment dupliquer la pièce ' + volume.nom;
        this.matDialog
            .open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.descriptionBienService.dupliquerVolume(this.currentBien, this.currentNiveau, volume);
                    this.refreshJustificationsVolumes.emit();
                }
            });
    }

    handleSupprimerVolume($event, volume) {
        $event.stopPropagation();
        const message = 'Voulez-vous vraiment supprimer la pièce ' + volume.nom;
        this.matDialog
            .open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.descriptionBienService.supprimerVolume(this.currentNiveau, volume);
                    this.refreshJustificationsVolumes.emit();
                }
            });
    }

    handleOrdreDescendreVolume($event, volume) {
        $event.stopPropagation();
        const index = this.currentNiveau.volumes.indexOf(volume);
        this.currentNiveau.volumes.splice(index, 1);
        this.currentNiveau.volumes.splice(index + 1, 0, volume);
    }

    handleOrdreMonterVolume($event, volume) {
        $event.stopPropagation();
        const index = this.currentNiveau.volumes.indexOf(volume);
        this.currentNiveau.volumes.splice(index, 1);
        this.currentNiveau.volumes.splice(index - 1, 0, volume);
    }

    handleClickVolume(volume) {
        if (volume === this.selectedVolume) {
            this.selectedVolume = null;
        } else {
            this.selectedVolume = volume;
        }
        this.selectedVolumeChange.emit(this.selectedVolume);
    }

    refreshJustificationNiveau() {
        this.niveauAJustifier =
            this.currentNiveau.volumes.filter(
                (it) =>
                    !it.valeursParametres[PARAM_VOLUME_VISITE] ||
                    (it.valeursParametres[PARAM_VOLUME_VISITE] &&
                        (it.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.KO ||
                            it.valeursParametres[PARAM_VOLUME_VISITE] === VOLUME_VISITE.WARNING) &&
                        !it.justifie)
            ).length > 0;
    }

    handleSansDescriptionChange($event) {
        this.sansDescriptionChange.emit($event);
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { InterventionService } from '../../../../services/intervention.service';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { DiagnosticHandlerService } from '../../../../services/diagnostic-handler.service';
import { DescriptionBienService } from '../../../../services/description-bien.service';
import { takeUntil } from 'rxjs/operators';
import { MergeBienService } from '../../../../services/merge-bien.service';
import { Intervention } from '../../../../model/intervention.model';

@Component({
    selector: 'app-description-bien-fusion-volume',
    templateUrl: './description-bien-fusion-volume.component.html',
    styleUrls: ['./description-bien-fusion-volume.component.scss'],
})
export class DescriptionBienFusionVolumeComponent extends BaseComponent {
    @Input()
    currentBien: Bien;

    @Input()
    currentVolume: Volume;

    @Output()
    currentVolumeChange: EventEmitter<Volume> = new EventEmitter<Volume>();

    niveauVolumeToMerge: Niveau;
    volumeToMerge: Volume;

    intervention: Intervention;

    constructor(
        private readonly interventionService: InterventionService,
        private readonly descriptionBienService: DescriptionBienService,
        private readonly mergeBienService: MergeBienService
    ) {
        super();
    }

    ngOnInit() {
        this.interventionService
            .getCurrentIntervention()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((intervention) => {
                this.intervention = intervention;
            });

        this.descriptionBienService
            .getVolumeToMerge()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((volumeToMerge) => {
                this.volumeToMerge = volumeToMerge;
                if (volumeToMerge) {
                    this.niveauVolumeToMerge = this.currentBien.description.find((niveau) =>
                        niveau.volumes.some((volume) => volume.id === this.volumeToMerge.id)
                    );
                }
            });
    }

    handleCancelMerge() {
        this.descriptionBienService.setVolumeToMerge(null);
    }

    handleMergeVolume() {
        this.mergeBienService
            .mergeVolumes(this.currentBien, this.currentVolume, this.volumeToMerge, this.intervention)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.currentVolume = this.volumeToMerge;
                this.descriptionBienService.setVolumeToMerge(null);
                this.currentVolumeChange.emit(this.currentVolume);
            });
    }
}

<div class="d-flex w-100 h-100" *ngIf="selectedOuvrageToCopyContext | async as ouvrageToCopyContext">
    <div class="w-20 h-100 d-flex flex-column overflow-auto border-right">
        <div class="d-flex flex-column pt-3 px-3 font-weight-light">Ouvrages</div>
        <div class="d-flex flex-row border-bottom font-weight-light align-items-center">
            <mat-checkbox
                matTooltip="(Dé)cocher tout"
                [checked]="ouvragesSelectionnes.length === ouvragesToDisplay.length"
                [indeterminate]="ouvragesSelectionnes.length > 0 && ouvragesSelectionnes.length < ouvragesToDisplay.length"
                style="padding-top: 7px"
                (change)="toggleAllOuvrage($event)"
                ><span class="subtitle-2">Tout</span></mat-checkbox
            >
            <div class="flex-grow-1"></div>
            <button
                mat-icon-button
                [disabled]="ouvragesSelectionnes.length !== 1 && !selectedOuvrageEnfant"
                (click)="copyOuvrage(selectedOuvrageEnfant?.id || ouvragesSelectionnes[0])"
            >
                <mat-icon>brush</mat-icon>
            </button>
        </div>
        <div class="d-flex flex-column overflow-hidden">
            <div class="flex-grow-1 overflow-auto">
                <div
                    *ngFor="let ouvrage of ouvragesToDisplay"
                    class="d-flex align-items-center border-bottom c-pointer"
                    [ngClass]="{
                        'bg-primary': selectedOuvrageEnfant === ouvrage,
                        'text-white': selectedOuvrageEnfant === ouvrage
                    }"
                >
                    <div>
                        <mat-checkbox
                            [checked]="ouvragesSelectionnes.includes(ouvrage.id)"
                            style="padding-top: 7px"
                            (change)="toggleOuvrage(ouvrage, $event)"
                        ></mat-checkbox>
                    </div>
                    <div (click)="handleSelectOuvrage(ouvrage)" class="py-2 pl-2 text-small flex-grow-1">
                        {{ ouvrage.nom }}
                        <div class="d-inline-block text-nowrap ml-1" *ngIf="ouvrage.parametres?.TYPE_OUVERTURE">
                            ({{ ouvrage.parametres?.TYPE_OUVERTURE }})
                        </div>
                        <div class="d-inline-block text-nowrap ml-1" *ngIf="ouvrage | typeVitrage as typeVitrage">({{ typeVitrage }})</div>
                        <div
                            class="d-inline-block text-nowrap ml-1"
                            *ngIf="ouvrage.parametres?.LOCALISATION_SUR_OUVRAGE | ouvrageName: currentVolume.ouvragesAControler as localisation"
                        >
                            ({{ localisation }} )
                        </div>
                    </div>
                    <div (click)="handleSelectOuvrage(ouvrage)">
                        <button
                            *ngIf="!ouvrage.objectId"
                            class="small-icon-button p-0"
                            mat-icon-button
                            matTooltip="Dupliquer l'ouvrage"
                            [disabled]="readonlyMode"
                            (click)="handleDupliquerOuvrage($event, ouvrage)"
                        >
                            <mat-icon>content_copy</mat-icon>
                        </button>
                        <button
                            *ngIf="!ouvrage.objectId"
                            class="small-icon-button p-0 warn"
                            mat-icon-button
                            matTooltip="Supprimer l'ouvrage"
                            [disabled]="readonlyMode"
                            (click)="handleSupprimerOuvrage($event, ouvrage)"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="p-2 d-flex flex-column align-items-center">
                <button
                    mat-raised-button
                    color="accent"
                    class="my-auto"
                    [disabled]="readonlyMode"
                    *ngIf="!ouvrageToCopyContext.enabled && !currentVolume.spaceId"
                    (click)="handleAjouterOuvrage()"
                >
                    <span>Ajouter</span>
                </button>
            </div>
        </div>
        <div class="flex-grow-1"></div>
        <app-copier-coller-ouvrages
            class="d-flex flex-grow-0 align-items-center justify-content-center"
            [canCopy]="!!ouvragesSelectionnes.length || selectedOuvrageEnfant?.id !== ouvrageToCopyContext.ouvrage?.id"
            *ngIf="ouvrageToCopyContext.enabled"
            (doCopy)="copierProprietesOuvrages()"
        ></app-copier-coller-ouvrages>
    </div>
    <div *ngIf="selectedOuvrageEnfant" class="h-100 w-25 border-right">
        <app-description-bien-modal-ouvrages-parametres
            class="d-flex h-100 w-100"
            [readonlyMode]="readonlyMode"
            [(ouvrageToEdit)]="selectedOuvrageEnfant"
            [listNomsOuvrages]="listNomsOuvrages"
            [listOuvragesCurrentVolume]="currentVolume.ouvragesAControler"
        ></app-description-bien-modal-ouvrages-parametres>
    </div>
    <div
        *ngIf="
            !readonlyMode &&
            selectedOuvrageEnfant &&
            (afficherChoixParties || selectedOuvrageEnfant.partiesOuvrages.length === 0) &&
            !currentVolume.spaceId
        "
        class="d-flex flex-column w-60 h-100 justify-content-center m-0"
    >
        <div class="flex-grow-0 d-flex justify-content-between align-items-center mx-3 mt-3">
            <span>Sélectionner une ou plusieurs parties d'ouvrages</span>
            <mat-form-field appearance="outline" class="w-33 white-search-form-field small-search-form-field">
                <mat-icon matPrefix>search</mat-icon>
                <input
                    matInput
                    type="text"
                    (keyup)="onKeyupSearchCategoriesOuvragesSousParties($event)"
                    [(ngModel)]="searchCategoriesOuvragesSousParties"
                    placeholder="Rechercher"
                />
                <button
                    *ngIf="searchCategoriesOuvragesSousParties"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="clearSearchCategoriesOuvragesSousParties()"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div *ngIf="listCategoriesOuvragesEnfantsToDisplay.length > 0" class="d-flex flex-grow-1 overflow-hidden flex-column align-items-center">
            <div class="w-100 d-flex row no-gutters justify-content-center overflow-auto">
                <mat-card
                    *ngFor="let categorieOuvrageEnfant of listCategoriesOuvragesEnfantsToDisplay"
                    class="d-flex col-2 categorie-ouvrage-card c-pointer"
                >
                    <mat-card-content
                        class="w-100 h-100 mh-100 d-flex flex-column justify-content-center align-items-center text-center"
                        [ngClass]="{
                            'bg-primary': selectedCategoriesOuvragesEnfants.includes(categorieOuvrageEnfant),
                            'text-white': selectedCategoriesOuvragesEnfants.includes(categorieOuvrageEnfant)
                        }"
                        (click)="handleSelectionnerCategorieSousPartie(categorieOuvrageEnfant)"
                    >
                        <span class="text-small">{{ categorieOuvrageEnfant.nom }}</span>
                    </mat-card-content>
                </mat-card>
            </div>
            <div *ngIf="listCategoriesOuvragesEnfantsToDisplay.length > 0" class="w-100 d-flex justify-content-center align-items-center py-3">
                <button mat-raised-button color="accent" class="my-auto" [disabled]="readonlyMode" (click)="handleChoisirSousParties()">
                    <span>Choisir</span>
                </button>
            </div>
        </div>
        <div
            *ngIf="listCategoriesOuvragesEnfantsToDisplay.length === 0"
            class="d-flex row no-gutters flex-grow-1 justify-content-center overflow-auto mt-3"
        >
            Aucune catégorie supplémentaire ne correspond aux critères
        </div>
    </div>
    <div
        *ngIf="selectedOuvrageEnfant && !afficherChoixParties && selectedOuvrageEnfant.partiesOuvrages.length != 0"
        class="d-flex flex-column w-20 h-100 border-right m-0"
    >
        <div class="d-flex flex-column border-bottom p-3 font-weight-light">Parties d'ouvrage</div>
        <div class="d-flex flex-column overflow-hidden">
            <div class="flex-grow-1 overflow-auto">
                <div
                    *ngFor="let partieOuvrage of selectedOuvrageEnfant.partiesOuvrages; trackBy: identifyOuvrage; let index = index"
                    class="d-flex align-items-center justify-content-between border-bottom c-pointer py-2"
                    [ngClass]="{
                        'bg-primary': selectedPartieOuvrage === partieOuvrage,
                        'text-white': selectedPartieOuvrage === partieOuvrage
                    }"
                    (click)="handleSelectionnerSousPartieOuvrage(partieOuvrage)"
                >
                    <span class="pl-2 text-small">{{ partieOuvrage.nom }}</span>
                    <div class="flex-grow-1"></div>
                    <ng-container *ngIf="selectedPartieOuvrage === partieOuvrage && !selectedOuvrageEnfant.objectId">
                        <div>
                            <button
                                class="w-50"
                                mat-icon-button
                                matTooltip="Descendre"
                                [disabled]="readonlyMode || index === selectedOuvrageEnfant.partiesOuvrages.length - 1"
                                (click)="deplacerPartieOuvrage($event, index, index + 1)"
                            >
                                <mat-icon>expand_more</mat-icon>
                            </button>
                        </div>
                        <div>
                            <button
                                class="w-50"
                                mat-icon-button
                                matTooltip="Monter"
                                [disabled]="readonlyMode || index === 0"
                                (click)="deplacerPartieOuvrage($event, index, index - 1)"
                            >
                                <mat-icon>expand_less</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                    <div>
                        <button
                            *ngIf="!selectedOuvrageEnfant.objectId"
                            class="small-icon-button p-0 warn"
                            mat-icon-button
                            matTooltip="Supprimer la partie d'ouvrage"
                            [disabled]="readonlyMode"
                            (click)="handleSupprimerPartieOuvrage($event, partieOuvrage)"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-grow-0 align-items-center justify-content-center p-2">
                <button
                    mat-raised-button
                    color="accent"
                    class="my-auto mx-1"
                    *ngIf="!readonlyMode && !currentVolume.spaceId"
                    (click)="handleAjouterSousParties()"
                >
                    <span>Ajouter</span>
                </button>
                <!--                <button mat-raised-button color="accent" class="my-auto mx-1" (click)="handleClonerOuvrage()">-->
                <!--                    <span>Dupliquer existant</span>-->
                <!--                </button>-->
            </div>
        </div>
    </div>
    <div *ngIf="selectedPartieOuvrage && selectedOuvrageEnfant" class="d-flex flex-column flex-grow-1 h-100 border-right justify-content-center m-0">
        <div class="d-flex flex-row border-bottom p-3 font-weight-light justify-content-between align-items-center">
            <div>Paramètres</div>
            <button
                mat-icon-button
                [disabled]="ouvragesSelectionnes.length !== 1 && !selectedOuvrageEnfant"
                (click)="copyOuvrage(selectedOuvrageEnfant?.id || ouvragesSelectionnes[0], selectedPartieOuvrage.id, true)"
            >
                <mat-icon>brush</mat-icon>
            </button>
        </div>
        <div class="flex-grow-1 w-100 overflow-auto d-flex flex-column align-items-center pt-3">
            <app-description-bien-modal-ouvrages-details
                class="w-100"
                [(ouvrageToEdit)]="selectedPartieOuvrage"
                [readonlyMode]="readonlyMode"
                [listOuvragesCurrentVolume]="currentVolume.ouvragesAControler"
            ></app-description-bien-modal-ouvrages-details>
        </div>
        <app-copier-coller-ouvrages
            class="d-flex flex-grow-0 align-items-center justify-content-center"
            [canCopy]="true"
            *ngIf="ouvrageToCopyContext.onlyRevetement"
            (doCopy)="copierProprietesOuvrages(selectedPartieOuvrage)"
        ></app-copier-coller-ouvrages>
    </div>
</div>

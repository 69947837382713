import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent, group } from 'src/app/commons-lib';
import { Bien, ElementAControler, Niveau, Volume } from '../../../../model/bien.model';
import { DescriptionBienModalPieceComponent } from '../description-bien-modal-piece/description-bien-modal-piece.component';
import { MatDialog } from '@angular/material/dialog';
import { falseOption, trueOption } from '../../../../shared/constants/states.constants';
import { StateChoiceBoxes } from '../../../../model/states.model';
import {
    CODE_BIM_PARAM_ESPACE_HUMIDE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    PARAM_TOOLTIP_NON,
    PARAM_TOOLTIP_OUI,
    PARAM_VOLUME_VISITE,
    VOLUME_VISITE,
} from 'src/app/shared/constants/cndiag.constants';
import {
    EquipementsOuvragesModalData,
    INDEXES_TABS_MODAL_EQUIPEMENTS_OUVRAGES,
} from '../description-bien-modal-equipements-ouvrages/description-bien-modal-equipements-ouvrages.component';
import { combineLatest, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { InterventionService } from '../../../../services/intervention.service';
import { ReferenceService } from '../../../../services/reference.service';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { ReferencePrestation } from '../../../../model/reference-prestation.model';
import { ConfirmDialogComponent } from '../../../../lib/confirmation/confirm-dialog.component';
import { CommentModalComponent } from '../../../shared/comment-modal/comment-modal.component';
import { DialogUtils } from '../../../../utils/dialog.utils';
import { DescriptionBienService } from '../../../../services/description-bien.service';
import { Intervention } from '../../../../model/intervention.model';
import { CategorieOuvrage, OuvrageAControler, Substrat } from '../../../../model/categorie-ouvrage.model';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';
import { NiveauModalComponent } from '../../../shared/niveau-modal/niveau-modal.component';
import { MergeBienService } from '../../../../services/merge-bien.service';
import { NotificationService } from '@acenv/commons-lib';
import { Equipement } from '../../../../model/equipement.model';
import { ReferenceApiService } from '../../../../services/reference-api.service';
import { CategorieEquipement } from '../../../../model/categorie-equipement.model';

@Component({
    selector: 'app-description-bien-details-piece',
    templateUrl: './description-bien-details-piece.component.html',
    styleUrls: ['./description-bien-details-piece.component.scss'],
})
export class DescriptionBienDetailsPieceComponent extends BaseComponent {
    @Input()
    readonlyMode: boolean = false;

    @Input()
    currentBien: Bien;

    @Input() set currentVolume(newVolume: Volume) {
        this._currentVolume = newVolume;
        this.filterElementsToDisplay();
        this.selectedRefPrestations = [];
        this.selectedRefPrestationsChange.emit(this.selectedRefPrestations);
        this.refreshCategoriesOuvragesCurrentVolume();
    }

    @Input()
    currentNiveau: Niveau;

    @Input()
    selectedRefPrestations: ReferencePrestation[] = [];

    @Output()
    openDialogEquipementOuvrage: EventEmitter<EquipementsOuvragesModalData> = new EventEmitter<EquipementsOuvragesModalData>();

    @Output()
    refreshJustificationsVolumes: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    currentVolumeChange: EventEmitter<Volume> = new EventEmitter<Volume>();

    @Output()
    selectedRefPrestationsChange: EventEmitter<ReferencePrestation[]> = new EventEmitter<ReferencePrestation[]>();

    @Output()
    refreshCnBuilding: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    clickRetour: EventEmitter<any> = new EventEmitter<any>();

    private _currentVolume: Volume;

    get currentVolume() {
        return this._currentVolume;
    }

    private intervention: Intervention;

    trueOption: StateChoiceBoxes = trueOption;
    falseOption: StateChoiceBoxes = falseOption;

    tooltipParametresVolume = [PARAM_TOOLTIP_OUI, PARAM_TOOLTIP_NON];

    CODE_BIM_PARAM_ESPACE_HUMIDE = CODE_BIM_PARAM_ESPACE_HUMIDE;
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ = CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ;
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE = CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE;
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE = CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE;

    PARAM_VOLUME_VISITE = PARAM_VOLUME_VISITE;
    VOLUME_VISITE = VOLUME_VISITE;

    listElementsToDisplay: Equipement[];

    refPrestations: ReferencePrestation[] = [];

    mapCategoriesOuvrages: Map<string, CategorieOuvrage>;
    currentOuvragesList: {
        categories: {
            key: string;
            nom: string;
            substrats: string[];
            count: number;
        }[];
        key: string;
        nom: string;
    }[] = [];

    private typeVolumes: TypeVolume[];
    allCategoriesEquipements: CategorieEquipement[] = [];

    constructor(
        private readonly dialog: MatDialog,
        private readonly descriptionBienService: DescriptionBienService,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionService: InterventionService,
        private readonly mergeBienService: MergeBienService,
        private readonly notificationService: NotificationService,
        private readonly referenceApiService: ReferenceApiService
    ) {
        super();
    }

    ngOnInit() {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService.getCurrentIntervention(),
                    this.referenceService.findAllReferencePrestations(),
                    this.referenceService.forceReloadCategoriesOuvrages(),
                    this.referenceService.findAllTypesVolumes(),
                    this.referenceApiService.findAllCategorieEquipement(),
                ]).pipe(takeUntil(this.ngUnsubscribe))
            )
            .subscribe(([intervention, referencePrestations, categoriesOuvrages, typeVolumes, categoriesEquipements]) => {
                this.mapCategoriesOuvrages = new Map<string, CategorieOuvrage>(categoriesOuvrages.map((it) => [it.code, it]));
                this.refreshCategoriesOuvragesCurrentVolume();

                this.intervention = intervention;
                referencePrestations.forEach((it) => {
                    this.refPrestations[it.typePrestation] = it;
                });
                this.filterElementsToDisplay();

                this.typeVolumes = typeVolumes;
                this.allCategoriesEquipements = categoriesEquipements;
            });
        this.dialog.afterAllClosed.pipe(takeUntil(this.ngUnsubscribe)).subscribe((it) => {
            this.filterElementsToDisplay();
            this.refreshCategoriesOuvragesCurrentVolume();
        });
    }

    handleSelectedRefPrestations($event) {
        this.selectedRefPrestations = $event;
        this.selectedRefPrestationsChange.emit(this.selectedRefPrestations);
        this.filterElementsToDisplay();
    }

    private filterElementsToDisplay() {
        this.listElementsToDisplay = this.currentVolume.equipements.filter(
            (it) =>
                this.selectedRefPrestations.length === 0 ||
                this.referenceService
                    .getTypesPrestationsAssocie(it.productTypeCodeBim)
                    .some((typePresta) => this.selectedRefPrestations.map((ref) => ref.typePrestation).includes(typePresta))
        );
    }

    handleEditVolume() {
        const listNomsVolumes = this.currentNiveau.volumes
            .filter((it) => it.id != this.currentVolume.id)
            .map((it) => it.nom.toLocaleUpperCase().trim());

        this.dialog
            .open(DescriptionBienModalPieceComponent, {
                width: '600px',
                data: {
                    volume: this.currentVolume,
                    niveau: this.currentNiveau,
                    listNomsVolumes: listNomsVolumes,
                    bien: this.currentBien,
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter((it) => !!it),
                switchMap(() => this.descriptionBienService.editVolumeInMap(this.currentBien, this.currentNiveau, this._currentVolume))
            )
            .subscribe();
    }

    handleAddOuvrage() {
        this.openDialogEquipementOuvrage.emit({ tabToOpen: INDEXES_TABS_MODAL_EQUIPEMENTS_OUVRAGES.OUVRAGES });
    }

    handleAddEquipement() {
        this.openDialogEquipementOuvrage.emit({ tabToOpen: INDEXES_TABS_MODAL_EQUIPEMENTS_OUVRAGES.EQUIPEMENTS });
    }

    handleClickVolumeVisite(nouvelEtatVolumeVisite: VOLUME_VISITE) {
        if (this.currentVolume.valeursParametres[PARAM_VOLUME_VISITE] != VOLUME_VISITE.OK) {
            const justificationNonVisiteVolume = this.intervention.commentaires.filter(
                (it) => it.type === 'JUSTIFICATION_NON_VISITE' && this.currentVolume.commentairesId.includes(it.id)
            );
            if (justificationNonVisiteVolume.length > 0) {
                const message = 'Voulez-vous également supprimer la justification de non visite ?';
                this.dialog
                    .open(ConfirmDialogComponent, {
                        data: {
                            message,
                            confirmLabel: 'Oui',
                            cancelLabel: 'Non',
                        },
                    })
                    .afterClosed()
                    .subscribe((result) => {
                        if (result) {
                            this.descriptionBienService.supprimerCommentairesFromVolume(this.currentVolume, ['JUSTIFICATION_NON_VISITE']);
                        }
                        this.changeVolumeVisite(nouvelEtatVolumeVisite);
                    });
            } else {
                this.changeVolumeVisite(nouvelEtatVolumeVisite);
            }
        } else {
            this.changeVolumeVisite(nouvelEtatVolumeVisite);
        }
    }

    private changeVolumeVisite(nouvelEtatVolumeVisite: VOLUME_VISITE) {
        this.currentVolume.valeursParametres[PARAM_VOLUME_VISITE] = nouvelEtatVolumeVisite;
        this.refreshJustificationsVolumes.emit();
    }

    handleCommenterVolume() {
        const typesCommentaires = ['JUSTIFICATION_NON_VISITE', 'CONSTATATION_DIVERSE', 'NOTE_PERSONNELLE'];
        this.openCommentModalDialog(typesCommentaires);
    }

    handleDeplacerVolume() {
        this.dialog
            .open(NiveauModalComponent, {
                data: {
                    currentBien: this.currentBien,
                    currentNiveau: this.currentNiveau,
                },
            })
            .afterClosed()
            .pipe(
                filter((res) => res && res !== false),
                switchMap((niveauCible) => {
                    return this.mergeBienService.transfertVolumeToNiveau(this.currentBien, this.currentVolume, niveauCible, this.intervention);
                }),
                tap(() => {
                    this.notificationService.success("Volume déplacé. L'intervention a été modifiée");
                    this.refreshJustificationsVolumes.emit();
                })
            )
            .subscribe();
    }

    handleSupprimerVolume() {
        const message = 'Voulez-vous vraiment supprimer la pièce ' + this.currentVolume.nom;
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.descriptionBienService.supprimerVolume(this.currentNiveau, this.currentVolume);
                    this.currentVolumeChange.emit(null);
                    this.refreshJustificationsVolumes.emit();
                }
            });
    }

    handleDupliquerVolume() {
        this.descriptionBienService.dupliquerVolume(this.currentBien, this.currentNiveau, this.currentVolume);
        this.refreshJustificationsVolumes.emit();
    }

    handleSupprimerEquipement(elementAControler: Equipement) {
        const message = "Voulez-vous vraiment supprimer l'élément " + elementAControler.name;
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    message,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.descriptionBienService.supprimerElementAControler(this.currentVolume, elementAControler);
                    this.filterElementsToDisplay();
                }
            });
    }

    addJustificationNonVisite() {
        this.openCommentModalDialog(['JUSTIFICATION_NON_VISITE'], 'JUSTIFICATION_NON_VISITE');
    }

    handleParametrerCategorieOuvrageByKey(categorieOuvrageCode: string) {
        this.openDialogEquipementOuvrage.emit({
            tabToOpen: INDEXES_TABS_MODAL_EQUIPEMENTS_OUVRAGES.OUVRAGES,
            categorieOuvrageCode: categorieOuvrageCode,
        });
    }

    private openCommentModalDialog(typesCommentaires: string[], typeNouveauCommentaire?: string) {
        this.dialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    commentairesId: this.currentVolume.commentairesId,
                    typesCommentaires,
                    typeNouveauCommentaire: typeNouveauCommentaire,
                    replaceTypesCommentaires: true,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.currentVolume.commentairesId = result.commentairesId;
                    this.refreshJustificationsVolumes.emit();
                }
            });
    }

    /**
     * Reconstruit la liste des ouvrages à afficher (avec nombre et substrats) par rapport au volume sélectionné.
     * @private
     */
    private refreshCategoriesOuvragesCurrentVolume() {
        if (this.mapCategoriesOuvrages && this.currentVolume) {
            const ouvragesEtCategories = this.currentVolume.ouvragesAControler
                .map((ouvrage) => ({
                    ouvrage,
                    categorie: this.mapCategoriesOuvrages.get(ouvrage.codeCategorieOuvrage),
                }))
                .filter(({ categorie }) => !!categorie)
                .map(({ ouvrage, categorie }) => ({
                    ouvrage,
                    categorie,
                    substrats: [ouvrage.codeSubstrat, ...ouvrage.partiesOuvrages.map((it) => it.codeSubstrat)]
                        .map((codeSubstrat) => categorie.substrats.find((it) => it.code === codeSubstrat)?.nom)
                        .filter((it) => !!it),
                }));
            this.currentOuvragesList = Object.entries(group(ouvragesEtCategories, ({ categorie }) => categorie.lienCodeParent))
                .map(([key, ouvrageEtCategorie]) => ({
                    key,
                    nom: this.mapCategoriesOuvrages.get(key)?.nom,
                    categories: Object.entries(group(ouvrageEtCategorie, ({ categorie }) => categorie.code)).map(([key1, ouvrageEtCategorie]) => ({
                        key: key1,
                        nom: this.mapCategoriesOuvrages.get(key1)?.nom,
                        substrats: Array.from(new Set(ouvrageEtCategorie.flatMap((it) => it.substrats))),
                        count: ouvrageEtCategorie.length,
                    })),
                }))
                .filter((it) => it.categories.length > 0);
            this.currentOuvragesList.sort((a, b) => a?.nom.localeCompare(b?.nom));
        }
    }

    handleMergeVolume() {
        this.descriptionBienService.setVolumeToMerge(this.currentVolume);
        this.clickRetour.emit();
    }
}

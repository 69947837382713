import { Injectable } from '@angular/core';
import { SvgFormValues, SvgSideNavFormValues, SvgTopFormValues } from '../interfaces/export-svg-form-values.interface';
import { cn_building } from '@acenv/cnmap-editor';
import { SvgConfigType } from '../conf/export-svg-types.conf';
import { Espace } from '../../../../../../model/espace.model';
import { Bien } from '../../../../../../model/bien.model';

@Injectable({
    providedIn: 'root',
})
export class ExportSvgFormConfMapper {
    toFormValues(svgFormConf: SvgConfigType, biens: Bien[], espaces: Espace[]): SvgFormValues {
        return Object.assign(this.toTopFormValues(svgFormConf, false, biens, espaces), this.toSideNavFormValues(svgFormConf, biens, espaces));
    }

    toTopFormValues(svgFormConf: SvgConfigType, generateName: boolean = false, biens: Bien[], espaces: Espace[]): SvgTopFormValues {
        const mainControls = svgFormConf.main.controls;
        const detail = mainControls.detail.value;
        let nom = mainControls.nom.value;
        if (generateName && svgFormConf.bien.controls.bien.value) {
            const bien = biens.find((it) => it.id === svgFormConf.bien.controls.bien.value?.id);
            const niveau = bien.description.find((it) => it.id === svgFormConf.bien.controls.niveau.value?.id);
            const building = cn_building.unserialize(JSON.parse(bien.jsonPlan));
            const storey = building.find_storey(niveau?.storeyId);
            const storeyName = storey ? ` - Niveau - ${storey.get_storey_name()}` : '';
            nom = `${building.name} ${storeyName} - ${svgFormConf.name}`;
        }

        return {
            main: {
                nom,
                detail,
            },
        };
    }

    toSideNavFormValues(svgFormConf: SvgConfigType, biens: Bien[], espaces: Espace[]): SvgSideNavFormValues {
        const bienControls = svgFormConf.bien.controls;
        const espaceControls = svgFormConf.espace ? svgFormConf.espace.controls : undefined;
        const pageControls = svgFormConf.page.controls;
        const planGeneralControls = svgFormConf.plan.groups.general.controls;
        const planDisplayControls = svgFormConf.plan.groups.display.controls;
        const planElementsControls = svgFormConf.plan.groups.elements.controls;
        const planTextControls = svgFormConf.plan.groups.text.controls;
        const planCotesControls = svgFormConf.plan.groups.cotes.controls;
        const planEquipmentsConformiteControls = svgFormConf.equipment.groups.conformite.controls;
        const planZonesSurfaceControls = svgFormConf.zone.groups.surface.controls;
        const planHapSurfaceControls = svgFormConf.hap.groups.surface.controls;
        const planHapZoneControls = svgFormConf.hap.groups.zone.controls;
        const planHapPrelevementControls = svgFormConf.hap.groups.prelevement.controls;
        const planPolluantControls = svgFormConf.polluant?.controls;

        const bien = biens.find((it) => it.id === svgFormConf.bien.controls.bien.value.id);
        const niveau = bien?.description.find((it) => it.id === svgFormConf.bien.controls.niveau?.value?.id);
        const espace = espaces?.find((it) => it.id === svgFormConf.espace?.controls.espace.value.id);
        return {
            bien: {
                bien: bien,
                niveau: niveau,
            },
            espace: {
                espace: espace,
            },
            page: {
                format: pageControls.format.value,
            },
            plan: {
                general: {
                    scale: planGeneralControls.scale.value,
                    resolution: planGeneralControls.resolution.value,
                    fixedScale: planGeneralControls.fixedScale.value,
                    fixedPosition: planGeneralControls.fixedPosition.value,
                },
                display: {
                    render: planDisplayControls.render.value,
                    showGrid: planDisplayControls.showGrid.value,
                    showScale: planDisplayControls.showScale.value,
                    showBackground: planDisplayControls.showBackground.value,
                    showCompass: planDisplayControls.showCompass.value,
                },
                elements: {
                    showSpaces: planElementsControls.showSpaces.value,
                    showOuterWalls: planElementsControls.showOuterWalls.value,
                    showInnerWalls: planElementsControls.showInnerWalls.value,
                    showBalconies: planElementsControls.showBalconies.value,
                    showWindows: planElementsControls.showWindows.value,
                    showDoors: planElementsControls.showDoors.value,
                    showStairs: planElementsControls.showStairs.value,
                    showSlabOpenings: planElementsControls.showSlabOpenings.value,
                    showBeams: planElementsControls.showBeams.value,
                    showColumns: planElementsControls.showColumns.value,
                    showPipes: planElementsControls.showPipes.value,
                    showObjectsShape: planElementsControls.showObjectsShape.value,
                    showObjectsIcon: planElementsControls.showObjectsIcon.value,
                },
                text: {
                    showSpaceLabel: planTextControls.showSpaceLabel.value,
                    showSpaceArea: planTextControls.showSpaceArea.value,
                    showVisitLock: planTextControls.showVisitLock.value,
                    showMarkers: planTextControls.showMarkers.value,
                    showNumerotation: planTextControls.showNumerotation.value,
                    showLegend: planTextControls.showLegend.value,
                },
                cotes: {
                    showOuterMeasures: planCotesControls.showOuterMeasures.value,
                    showInnerMeasures: planCotesControls.showInnerMeasures.value,
                    showOpeningMeasures: planCotesControls.showOpeningMeasures.value,
                },
            },
            equipment: {
                conformite: {
                    showConformeObjects: planEquipmentsConformiteControls.showConformeObjects.value,
                    showNonConformeObjects: planEquipmentsConformiteControls.showNonConformeObjects.value,
                    showAJustifierObjects: planEquipmentsConformiteControls.showAJustifierObjects.value,
                    showNonVerifieObjects: planEquipmentsConformiteControls.showNonVerifieObjects.value,
                },
            },
            zone: {
                surface: {
                    showSurfacesComptabilisees: planZonesSurfaceControls.showSurfacesComptabilisees.value,
                    showSurfacesDecomptees: planZonesSurfaceControls.showSurfacesDecomptees.value,
                },
            },
            hap: {
                surface: {
                    showPerimetres: planHapSurfaceControls.showPerimetres.value,
                    showZones: planHapSurfaceControls.showZones.value,
                    showPrelevements: planHapSurfaceControls.showPrelevements.value,
                },
                zone: {
                    showZoneInferieurEgal50: planHapZoneControls.showZoneInferieurEgal50.value,
                    showZoneEntre51Et500: planHapZoneControls.showZoneEntre51Et500.value,
                    showZoneEntre501Et1000: planHapZoneControls.showZoneEntre501Et1000.value,
                    showZoneSuperieur1000: planHapZoneControls.showZoneSuperieur1000.value,
                    showZoneNonRealises: planHapZoneControls.showZoneNonRealises.value,
                },
                prelevement: {
                    showPrelevementInferieurEgal50: planHapPrelevementControls.showPrelevementInferieurEgal50.value,
                    showPrelevementEntre51Et500: planHapPrelevementControls.showPrelevementEntre51Et500.value,
                    showPrelevementEntre501Et1000: planHapPrelevementControls.showPrelevementEntre501Et1000.value,
                    showPrelevementSuperieur1000: planHapPrelevementControls.showPrelevementSuperieur1000.value,
                    showPrelevementNonRealises: planHapPrelevementControls.showPrelevementNonRealises.value,
                },
            },
            polluant: {
                perimetreId: planPolluantControls?.perimetreId?.value,
                showBesoins: planPolluantControls?.showBesoins?.value,
                showPerimetres: planPolluantControls?.showPerimetres?.value,
                zoneId: planPolluantControls?.zoneId?.value,
            },
        };
    }
}
